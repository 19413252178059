import {
  CogIcon,
  InformationCircleIcon,
  MusicNoteIcon,
  SparklesIcon,
} from '@heroicons/react/outline';

const navigation = [
  { name: 'Practice', href: '/practice', icon: MusicNoteIcon },
  { name: 'Settings', href: '/settings', icon: CogIcon },
  { name: "What's New", href: '/whats-new', icon: SparklesIcon },
  { name: 'About', href: '/', icon: InformationCircleIcon },
];

export default navigation;
