/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/noto-mono';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import * as Tone from 'tone';
import unmuteIosSilentMode from 'modules/unmute';
import './index.css';
import click from 'assets/audio/click.wav';
import ServiceWorkerRegistration from 'components/ServiceWorkerRegistration';
import App from './App';
import reportWebVitals from './reportWebVitals';

config.autoAddCss = false;

if (Tone.context.rawContext) {
  // HACK HACK: This is needed to keep audio playing on iOS when the user
  // switches to silent mode. See https://stackoverflow.com/a/46839941/127837
  unmuteIosSilentMode(Tone.context.rawContext);
}

ReactDOM.render(
  <React.StrictMode>
    <>
      {/*
        HACK HACK: The only reason this audio element is here is because it
        appears to prevent iOS from halting all audio when resuming from sleep.
        See https://stackoverflow.com/a/69546084/127837 🤷
      */}

      <audio className='hidden' src={click} />

      <App />

      <ServiceWorkerRegistration />
    </>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
