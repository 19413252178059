import React from 'react';
import PageLayout from 'components/PageLayout';
import Practice from 'pages/Practice';
import About from 'pages/About';
import Settings from 'pages/Settings';
import WhatsNew from 'pages/WhatsNew';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { SettingsProvider } from 'hooks/useSettings';
import { ForceInitialStepProvider } from 'hooks/useForceInitialStep';
import { InstallProvider } from 'hooks/useInstall';

function App() {
  return (
    <InstallProvider>
      <SettingsProvider>
        <ForceInitialStepProvider>
          <BrowserRouter>
            <PageLayout>
              <Routes>
                <Route
                  path='/'
                  element={
                    <About
                      canonicalUrl={`https://${process.env.REACT_APP_DOMAIN}`}
                    />
                  }
                />

                <Route
                  path='/practice/*'
                  element={
                    <Practice
                      canonicalUrl={`https://${process.env.REACT_APP_DOMAIN}/practice`}
                    />
                  }
                />

                <Route
                  path='/settings'
                  element={
                    <Settings
                      canonicalUrl={`https://${process.env.REACT_APP_DOMAIN}/settings`}
                    />
                  }
                />

                <Route
                  path='/whats-new'
                  element={
                    <WhatsNew
                      canonicalUrl={`https://${process.env.REACT_APP_DOMAIN}/whats-new`}
                    />
                  }
                />

                <Route path='*' element={<Navigate to='/' />} />
              </Routes>
            </PageLayout>
          </BrowserRouter>
        </ForceInitialStepProvider>
      </SettingsProvider>
    </InstallProvider>
  );
}

export default App;
