import React from 'react';
import useInstall from 'hooks/useInstall';
import { DeviceTabletIcon } from '@heroicons/react/outline';

export interface Props {
  className?: string;
  onClick?: () => void;
}

export default React.memo(({ className, onClick }: Props) => {
  const { installPrompt } = useInstall();
  if (!installPrompt) {
    return null;
  }

  return (
    <button
      type='button'
      className={`btn-normal ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }

        installPrompt();
      }}
    >
      <DeviceTabletIcon className='mr-2 h-6 w-6' />

      <span>Install</span>
    </button>
  );
});
