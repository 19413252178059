import React from 'react';
import { Helmet } from 'react-helmet';
import { NoteLetter } from 'modules/Notes';
import { FretboardConfig } from 'modules/Fretboard';
import StepPage from '../components/StepPage';
import FretboardPlayer, { Mode } from '../components/FretboardPlayer';

interface Props {
  canonicalUrl: string;
}

const noteLetters: NoteLetter[] = [
  'Ab',
  'A#',
  'Bb',
  'C#',
  'Db',
  'D#',
  'Eb',
  'F#',
  'Gb',
  'G#',
];

const mode: Mode = { type: 'EXERCISE_PLAYER' };

const instructions = (bpm: number, fretboardConfig: FretboardConfig) => (
  <div>
    <p className='mb-2'>
      <span>Play each note on strings&nbsp;</span>

      <strong>{fretboardConfig.stringTunings.length} through 1</strong>

      <span>, followed by&nbsp;</span>

      <strong>2 through {fretboardConfig.stringTunings.length}</strong>

      <span>, playing one string per beat at&nbsp;</span>

      <strong>{bpm} BPM</strong>

      <span> with your </span>

      <strong>first finger</strong>

      <span>.</span>
    </p>

    <p>
      <span>Move on to the next note when you can play the exercise&nbsp;</span>

      <strong>3 times with no mistakes</strong>

      <span>.</span>
    </p>
  </div>
);

export default function Step3({ canonicalUrl }: Props) {
  return (
    <StepPage instructions={instructions}>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <FretboardPlayer noteLetters={noteLetters} mode={mode} />
    </StepPage>
  );
}
