import * as Tone from 'tone';

const ALL_NOTE_LETTERS = [
  'Cbb',
  'Cb',
  'C',
  'C#',
  'Cx',
  'Dbb',
  'Db',
  'D',
  'D#',
  'Dx',
  'Ebb',
  'Eb',
  'E',
  'E#',
  'Ex',
  'Fbb',
  'Fb',
  'F',
  'F#',
  'Fx',
  'Gbb',
  'Gb',
  'G',
  'G#',
  'Gx',
  'Abb',
  'Ab',
  'A',
  'A#',
  'Ax',
  'Bbb',
  'Bb',
  'B',
  'B#',
  'Bx',
] as const;

type NoteLetterTuple = typeof ALL_NOTE_LETTERS;

export type NoteLetter = NoteLetterTuple[number];

export type ScaleIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type AccidentalSymbol =
  | 'double flat'
  | 'flat'
  | 'natural'
  | 'sharp'
  | 'double sharp';

export type NoteLetterInfo = {
  readonly letter: 'C' | 'D' | 'E' | 'F' | 'G' | 'A' | 'B';
  readonly symbol: AccidentalSymbol;
  readonly scaleIndex: ScaleIndex;
};

const NoteLetterInfos: ReadonlyMap<NoteLetter, NoteLetterInfo> = new Map<
  NoteLetter,
  NoteLetterInfo
>([
  ['Cbb', { letter: 'C', symbol: 'double flat', scaleIndex: 10 }],
  ['Cb', { letter: 'C', symbol: 'flat', scaleIndex: 11 }],
  ['C', { letter: 'C', symbol: 'natural', scaleIndex: 0 }],
  ['C#', { letter: 'C', symbol: 'sharp', scaleIndex: 1 }],
  ['Cx', { letter: 'C', symbol: 'double sharp', scaleIndex: 2 }],

  ['Dbb', { letter: 'D', symbol: 'double flat', scaleIndex: 0 }],
  ['Db', { letter: 'D', symbol: 'flat', scaleIndex: 1 }],
  ['D', { letter: 'D', symbol: 'natural', scaleIndex: 2 }],
  ['D#', { letter: 'D', symbol: 'sharp', scaleIndex: 3 }],
  ['Dx', { letter: 'D', symbol: 'double sharp', scaleIndex: 4 }],

  ['Ebb', { letter: 'E', symbol: 'double flat', scaleIndex: 2 }],
  ['Eb', { letter: 'E', symbol: 'flat', scaleIndex: 3 }],
  ['E', { letter: 'E', symbol: 'natural', scaleIndex: 4 }],
  ['E#', { letter: 'E', symbol: 'sharp', scaleIndex: 5 }],
  ['Ex', { letter: 'E', symbol: 'double sharp', scaleIndex: 6 }],

  ['Fbb', { letter: 'F', symbol: 'double flat', scaleIndex: 3 }],
  ['Fb', { letter: 'F', symbol: 'flat', scaleIndex: 4 }],
  ['F', { letter: 'F', symbol: 'natural', scaleIndex: 5 }],
  ['F#', { letter: 'F', symbol: 'sharp', scaleIndex: 6 }],
  ['Fx', { letter: 'F', symbol: 'double sharp', scaleIndex: 7 }],

  ['Gbb', { letter: 'G', symbol: 'double flat', scaleIndex: 5 }],
  ['Gb', { letter: 'G', symbol: 'flat', scaleIndex: 6 }],
  ['G', { letter: 'G', symbol: 'natural', scaleIndex: 7 }],
  ['G#', { letter: 'G', symbol: 'sharp', scaleIndex: 8 }],
  ['Gx', { letter: 'G', symbol: 'double sharp', scaleIndex: 9 }],

  ['Abb', { letter: 'A', symbol: 'double flat', scaleIndex: 7 }],
  ['Ab', { letter: 'A', symbol: 'flat', scaleIndex: 8 }],
  ['A', { letter: 'A', symbol: 'natural', scaleIndex: 9 }],
  ['A#', { letter: 'A', symbol: 'sharp', scaleIndex: 10 }],
  ['Ax', { letter: 'A', symbol: 'double sharp', scaleIndex: 11 }],

  ['Bbb', { letter: 'B', symbol: 'double flat', scaleIndex: 9 }],
  ['Bb', { letter: 'B', symbol: 'flat', scaleIndex: 10 }],
  ['B', { letter: 'B', symbol: 'natural', scaleIndex: 11 }],
  ['B#', { letter: 'B', symbol: 'sharp', scaleIndex: 0 }],
  ['Bx', { letter: 'B', symbol: 'double sharp', scaleIndex: 1 }],
]);

export type NoteInfo = {
  letter: NoteLetter;
  octave: number;
};

export function getNoteLetterInfo(noteLetter: NoteLetter) {
  const letterInfo = NoteLetterInfos.get(noteLetter);
  if (!letterInfo) {
    throw new Error(`Unknown note pitch: ${noteLetter}`);
  }

  return letterInfo;
}

const NoteRegExp = /^([a-g]{1})(b|#|x|bb)?(-?[0-9]+)/i;

export function getNoteInfo(note: Tone.Unit.Note): NoteInfo {
  const match = note.match(NoteRegExp);
  if (!match) {
    throw new Error(`Unknown note: ${note}`);
  }

  return {
    letter: `${match[1].toUpperCase()}${
      match[2] ? match[2] : ''
    }` as NoteLetter,
    octave: parseInt(match[3], 10),
  };
}

export const exportedForTesting = {
  ALL_NOTE_LETTERS,
};
