import * as Tone from 'tone';
import WebAudioTinySynth from 'webaudio-tinysynth/webaudio-tinysynth';

const GeneralMidiProgramElectricGuitarClean = 27;

export default class GuitarSound {
  readonly synth;

  constructor() {
    this.synth = new WebAudioTinySynth({ internalcontext: 0 });
  }

  toDestination(): GuitarSound {
    this.synth.setAudioContext(
      Tone.context.rawContext,
      Tone.context.rawContext.destination
    );

    this.synth.setProgram(1, GeneralMidiProgramElectricGuitarClean);

    return this;
  }

  triggerAttackRelease(
    note: Tone.Unit.Frequency,
    duration: Tone.Unit.Time,
    time: Tone.Unit.Time
  ): GuitarSound {
    const midiNote = Tone.Frequency(note).toMidi();
    const noteOnTime = Tone.Time(time).toSeconds();
    const noteOffTime = noteOnTime + Tone.Time(duration).toSeconds();
    this.synth.noteOn(1, midiNote, 100, noteOnTime);
    this.synth.noteOff(1, midiNote, noteOffTime);
    return this;
  }

  dispose(): GuitarSound {
    this.synth.reset();
    return this;
  }
}
