import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import {
  FretboardConfig,
  FretFingering as FretFingeringBase,
  getFretFingeringFrequency,
  getNaturalNoteFingerings,
  Handedness,
} from 'modules/Fretboard';
import { getNoteInfo } from 'modules/Notes';
import Note from 'components/Note';
import useSettings, { getFretboardConfig } from 'hooks/useSettings';
import GuitarSound from 'modules/GuitarSound';
import * as Tone from 'tone';
import { RippleButton } from 'components/Ripple';
import style from './style.module.scss';

interface FretFingering extends FretFingeringBase {
  className?: string;
  label?: ReactNode;
}

export interface Props {
  className?: string;
  config: FretboardConfig;
  handedness: Handedness;
  showFretFingerings?: boolean;
  fretFingerings?: FretFingering[];
  showStringTunings?: boolean;
  highlightStringNumbers?: Set<number>;
  dotOpacity?: number;
}

export function useNaturalNoteFingerings() {
  const [{ fretboardConfigId }] = useSettings();

  const fretboardConfig = getFretboardConfig(fretboardConfigId);

  return useMemo(
    () =>
      getNaturalNoteFingerings(fretboardConfig).map(
        ([noteLetter, fingering]) => ({
          ...fingering,
          className: '!opacity-50 !text-xl',
          label: <Note className='font-bold text-gray-900' note={noteLetter} />,
        })
      ),
    [fretboardConfig]
  );
}

const toStringWiseFretFingerings = (fretFingerings: FretFingering[]) =>
  fretFingerings.reduce(
    (acc: Map<number, Map<number, FretFingering>>, fretFingering) => {
      const stringIndex = fretFingering.stringNumber - 1;
      const fretIndex = fretFingering.fretNumber;
      if (acc.get(stringIndex) === undefined) {
        acc.set(stringIndex, new Map<number, FretFingering>());
      }

      acc.get(stringIndex)?.set(fretIndex, fretFingering);

      return acc;
    },
    new Map<number, Map<number, FretFingering>>()
  );

export default React.memo(
  ({
    className,
    config,
    handedness,
    showStringTunings = true,
    showFretFingerings = true,
    fretFingerings,
    highlightStringNumbers,
    dotOpacity,
  }: Props) => {
    const guitarSound = useRef<GuitarSound>();
    const stringWiseFretFingerings =
      fretFingerings && toStringWiseFretFingerings(fretFingerings);

    const stringIndexes = Array.from(
      { length: config.stringTunings.length },
      (_, i) => i
    );

    const fretNumbers = Array.from(
      { length: config.fretCount + 1 },
      (_, i) => i
    );

    useEffect(() => {
      if (!guitarSound.current) {
        guitarSound.current = new GuitarSound().toDestination();
      }

      return () => {
        guitarSound.current?.dispose();
      };
    }, [showStringTunings]);

    return (
      <div
        className={`
        w-full
        ${style.container}
        ${className}
      `}
        style={{
          ['--fret-count' as any]: config.fretCount,
          ['--string-count' as any]: config.stringTunings.length,
          ['--dot-opacity' as any]: dotOpacity,
        }}
      >
        <div
          className={`
          relative
          -mb-6 flex
          w-full
          justify-evenly
          ${handedness === 'LEFT' ? 'flex-row-reverse' : ''}
        `}
        >
          {fretNumbers.map((fretNumber) => (
            <div
              className={`${style.fret} ${
                handedness === 'RIGHT' ? style.rightHanded : style.leftHanded
              }`}
              key={fretNumber}
            >
              <div
                className={`${style.dotContainer} ${
                  handedness === 'RIGHT' ? style.rightHanded : style.leftHanded
                }`}
              >
                {(config.fretHasSingleDot(fretNumber) ||
                  config.fretHasDoubleDot(fretNumber)) && (
                  <div className={style.dot} />
                )}

                {config.fretHasDoubleDot(fretNumber) && (
                  <div className={style.dot} />
                )}
              </div>

              {stringIndexes.map((stringIndex) => (
                <div className={style.fretString} key={stringIndex}>
                  {fretNumber === 0 && showStringTunings && (
                    <Note
                      className={style.stringTuning}
                      note={
                        getNoteInfo(config.stringTunings[stringIndex]).letter
                      }
                    />
                  )}

                  <RippleButton
                    type='button'
                    className={style.fretFingeringContainer}
                    onClick={() =>
                      guitarSound.current?.triggerAttackRelease(
                        getFretFingeringFrequency(config, {
                          stringNumber: stringIndex + 1,
                          fretNumber,
                        }).valueOf(),
                        '8n',
                        Tone.now()
                      )
                    }
                  >
                    <div
                      className={`
                        ${style.fretFingering}
                        ${
                          showFretFingerings &&
                          stringWiseFretFingerings
                            ?.get(stringIndex)
                            ?.has(fretNumber)
                            ? style.showFingering
                            : ''
                        }
                        ${
                          stringWiseFretFingerings
                            ?.get(stringIndex)
                            ?.get(fretNumber)?.className
                        }
                      `}
                    >
                      {
                        stringWiseFretFingerings
                          ?.get(stringIndex)
                          ?.get(fretNumber)?.label
                      }
                    </div>
                  </RippleButton>
                </div>
              ))}
            </div>
          ))}

          <div className={style.fret} />

          <div className={style.strings}>
            {stringIndexes.map((stringIndex) => (
              <div
                className={`
                  ${style.string}
                  ${
                    highlightStringNumbers?.has(stringIndex + 1)
                      ? style.highlight
                      : ''
                  }
                `}
                key={stringIndex}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);
