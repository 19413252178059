/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import * as Tone from 'tone';
import stepDescriptors, { getStepHref } from 'modules/StepDescriptors';
import PageLayout from 'components/PageLayout';
import useSettings from 'hooks/useSettings';
import useForceInitialStep from 'hooks/useForceInitialStep';
import { useKeyPressEvent } from 'react-use';
import BmcLogo from 'components/BmcLogo';
import { RippleButton } from 'components/Ripple';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faKeyboard,
} from '@fortawesome/free-solid-svg-icons';
import { primaryInput } from 'detect-it';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

interface Props {
  canonicalUrl: string;
}

function MostRecentStep() {
  const [{ lastStepNumber }] = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    switch (lastStepNumber) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        navigate(getStepHref(lastStepNumber), { replace: true });
        break;
      default:
        navigate(getStepHref(1), { replace: true });
        break;
    }
  });

  return null;
}

const ensureToneStarted = async () => {
  if (Tone.getContext().state === 'running') {
    return;
  }

  await Tone.start();
};

const parseStepNumber = (stepNumber: string | undefined) => {
  const parsed = parseInt(stepNumber || '', 10);
  return Number.isInteger(parsed || '') ? parsed : 1;
};

export default function Practice({ canonicalUrl }: Props) {
  const navigate = useNavigate();
  const [forceInitialStep, setForceInitialStep] = useForceInitialStep();

  const [stepsMenuIsOpen, setStepsMenuIsOpen] = useState(false);
  const [keyboardHintModalIsOpen, setKeyboardHintModalIsOpen] = useState(false);
  const [{ bpm }, settingsDispatch] = useSettings();
  const match = useMatch('/practice/steps/:stepNumber');

  const currentStepNumber = parseStepNumber(match?.params.stepNumber);
  const nextLogicalStepNumber =
    currentStepNumber === stepDescriptors.length ? 2 : currentStepNumber + 1;

  const navigateToStep = (stepNumber: number) => {
    setStepsMenuIsOpen(false);
    navigate(getStepHref(stepNumber));
  };

  const nextStepNumber =
    currentStepNumber < stepDescriptors.length ? currentStepNumber + 1 : 1;

  const previousStepNumber =
    currentStepNumber > 1 ? currentStepNumber - 1 : stepDescriptors.length;

  useKeyPressEvent('s', () =>
    navigateToStep(nextLogicalStepNumber || nextStepNumber)
  );

  useEffect(() => {
    settingsDispatch({
      type: 'SET_LAST_STEP_NUMBER',
      lastStepNumber: currentStepNumber,
    });
  }, [currentStepNumber, settingsDispatch]);

  useEffect(() => {
    if (forceInitialStep) {
      setForceInitialStep(false);
      navigate(getStepHref(1), { replace: true });
    }
  }, [forceInitialStep, setForceInitialStep, navigate]);

  return (
    <PageLayout.NonScrollableContent>
      <div
        className='h-full'
        onTouchStart={ensureToneStarted}
        onTouchEnd={ensureToneStarted}
        onMouseDown={ensureToneStarted}
        onKeyDown={ensureToneStarted}
      >
        <div
          className='
            relative
            flex h-full flex-col
            items-center
          '
        >
          <Transition.Root show={stepsMenuIsOpen} as={Fragment}>
            <Dialog
              as='div'
              className='
                fixed
                inset-0
                z-10
                overflow-y-auto
              '
              onClose={setStepsMenuIsOpen}
            >
              <div
                className='
                  flex min-h-screen items-end
                  justify-center
                  px-4 pt-4 pb-20
                  text-center
                  sm:block
                  sm:p-0
                '
              >
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Dialog.Overlay
                    className='
                      fixed
                      inset-0
                      bg-gray-900 bg-opacity-75
                      transition-opacity
                    '
                  />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}

                <span
                  className='
                    hidden
                    sm:inline-block
                    sm:h-screen
                    sm:align-middle
                  '
                  aria-hidden='true'
                >
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='
                    opacity-0
                    translate-y-4 sm:translate-y-0
                    sm:scale-95'
                  enterTo='
                    opacity-100
                    translate-y-0
                    sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='
                    opacity-100
                    translate-y-0
                    sm:scale-100'
                  leaveTo='
                    opacity-0
                    translate-y-4 sm:translate-y-0
                    sm:scale-95'
                >
                  <div
                    className='
                      inline-block
                      transform
                      overflow-hidden
                      rounded-lg
                      bg-gray-800 px-4 pt-5
                      pb-4
                      text-left
                      align-bottom
                      shadow-xl transition-all
                      sm:my-8
                      sm:w-full
                      sm:max-w-lg
                      sm:p-6 sm:align-middle
                    '
                  >
                    <div
                      className='
                        absolute
                        top-0 right-0 z-30
                        pt-4 pr-4
                      '
                    >
                      <button
                        type='button'
                        className='btn-text-only'
                        aria-label='Close'
                        onClick={() => setStepsMenuIsOpen(false)}
                      >
                        <span className='sr-only'>Close</span>

                        <XIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>

                    <div className='flex items-start'>
                      <div
                        className='
                          mt-0 ml-4
                          text-left
                        '
                      >
                        <div>
                          <div className='flow-root'>
                            <ul
                              className='
                                -my-5
                                divide-y
                                divide-gray-600
                              '
                            >
                              {stepDescriptors.map((stepDescriptor, i) => {
                                const stepNumber = i + 1;
                                return (
                                  <li key={stepNumber} className='py-5'>
                                    <div className='relative'>
                                      <h3
                                        className={
                                          currentStepNumber === stepNumber
                                            ? 'text-indigo-300'
                                            : 'text-gray-200'
                                        }
                                      >
                                        <button
                                          type='button'
                                          onClick={() =>
                                            navigateToStep(stepNumber)
                                          }
                                          className='
                                            text-xl
                                            font-semibold
                                            hover:underline
                                            focus:outline-none
                                          '
                                        >
                                          {
                                            /* Extend touch target to entire panel */
                                            <span
                                              className='absolute inset-0 text-5xl'
                                              aria-hidden='true'
                                            />
                                          }

                                          <span>Step {stepNumber}</span>
                                        </button>
                                      </h3>

                                      <p
                                        className={`
                                          mt-1
                                          text-base
                                          ${
                                            currentStepNumber === stepNumber
                                              ? 'text-indigo-300'
                                              : 'text-gray-400'
                                          }
                                        `}
                                      >
                                        {stepDescriptor.summary(bpm)}
                                      </p>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <Transition.Root show={keyboardHintModalIsOpen} as={Fragment}>
            <Dialog
              as='div'
              className='
                fixed
                inset-0
                z-10
                overflow-y-auto
              '
              onClose={setKeyboardHintModalIsOpen}
            >
              <div
                className='
                  flex min-h-screen items-end
                  justify-center
                  px-4 pt-4 pb-20
                  text-center
                  sm:block
                  sm:p-0
                '
              >
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Dialog.Overlay
                    className='
                      fixed
                      inset-0
                      bg-gray-900 bg-opacity-75
                      transition-opacity
                    '
                  />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}

                <span
                  className='
                    hidden
                    sm:inline-block
                    sm:h-screen
                    sm:align-middle
                  '
                  aria-hidden='true'
                >
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='
                    opacity-0
                    translate-y-4 sm:translate-y-0
                    sm:scale-95'
                  enterTo='
                    opacity-100
                    translate-y-0
                    sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='
                    opacity-100
                    translate-y-0
                    sm:scale-100'
                  leaveTo='
                    opacity-0
                    translate-y-4 sm:translate-y-0
                    sm:scale-95'
                >
                  <div
                    className='
                      inline-block
                      transform
                      overflow-hidden
                      rounded-lg
                      bg-gray-800 px-4 pt-5
                      pb-4
                      text-left
                      align-bottom
                      shadow-xl transition-all
                      sm:my-8
                      sm:w-full
                      sm:max-w-lg
                      sm:p-6 sm:align-middle
                    '
                  >
                    <div
                      className='
                        absolute
                        top-0 right-0 z-30
                        pt-4 pr-4
                      '
                    >
                      <button
                        type='button'
                        className='btn-text-only'
                        aria-label='Close'
                        onClick={() => setKeyboardHintModalIsOpen(false)}
                      >
                        <span className='sr-only'>Close</span>

                        <XIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>

                    <h1 className='text-center text-xl text-white'>
                      Keyboard Shortcuts
                    </h1>

                    <table>
                      <tbody>
                        <tr>
                          <td className='p-2'>
                            <div className='flex justify-end'>
                              <div className='flex h-8 w-8 items-center justify-center rounded-md bg-gray-400 text-gray-800'>
                                <FontAwesomeIcon icon={faArrowRight} />
                              </div>
                            </div>
                          </td>

                          <td className='p-2'>Next Note / Increase BPM</td>
                        </tr>

                        <tr>
                          <td className='p-2'>
                            <div className='flex justify-end'>
                              <div className='flex h-8 w-8 items-center justify-center rounded-md bg-gray-400 text-gray-800'>
                                <FontAwesomeIcon icon={faArrowLeft} />
                              </div>
                            </div>
                          </td>

                          <td className='p-2'>Previous Note / Decrease BPM</td>
                        </tr>

                        <tr>
                          <td className='p-2'>
                            <div className='flex justify-end'>
                              <div className='flex h-8 w-20 items-center justify-center rounded-md bg-gray-400 text-gray-800'>
                                <span className='text-sm font-bold uppercase'>
                                  Space
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className='p-2'>Start/Stop Metronome</td>
                        </tr>

                        <tr>
                          <td className='p-2'>
                            <div className='flex justify-end'>
                              <div className='flex h-8 w-8 items-center justify-center rounded-md bg-gray-400 text-gray-800'>
                                <span className='text-sm font-bold uppercase'>
                                  S
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className='p-2'>Next Step</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div
            className='
              md:text-md
              flex w-full max-w-screen-lg
              flex-1
              flex-col
              justify-center
              text-[.82em]
              sm:text-sm
              md-h:relative
              md-h:top-2
              md-h:justify-evenly
              md-h:!text-xs
            '
          >
            <Routes>
              <Route path='steps/*'>
                <Route
                  path='1'
                  element={<Step1 canonicalUrl={`${canonicalUrl}/steps/1`} />}
                />

                <Route
                  path='2'
                  element={<Step2 canonicalUrl={`${canonicalUrl}/steps/2`} />}
                />

                <Route
                  path='3'
                  element={<Step3 canonicalUrl={`${canonicalUrl}/steps/3`} />}
                />

                <Route
                  path='4'
                  element={<Step4 canonicalUrl={`${canonicalUrl}/steps/4`} />}
                />

                <Route
                  path='5'
                  element={<Step5 canonicalUrl={`${canonicalUrl}/steps/5`} />}
                />

                <Route
                  path='6'
                  element={<Step6 canonicalUrl={`${canonicalUrl}/steps/6`} />}
                />

                <Route path='*' element={<MostRecentStep />} />
              </Route>

              <Route path='*' element={<MostRecentStep />} />
            </Routes>
          </div>

          {/* STEP NAV */}

          <div
            className='
              md:text-md
              relative mt-2 flex
              w-full
              items-center
              justify-center
              bg-gray-800/50
              py-4
              text-sm
              md-h:!text-sm
              sm-h:!text-xs
            '
          >
            {primaryInput === 'mouse' && (
              <div
                className='
                  absolute
                  left-0 right-0 -top-8
                  flex
                  items-center
                  justify-center
                  sm-h:!hidden
                '
              >
                <div className='flex w-full max-w-screen-lg items-center justify-center'>
                  <button
                    type='button'
                    aria-label='Keyboard Hints'
                    className='btn-text-only text-2xl'
                    onClick={() => setKeyboardHintModalIsOpen(true)}
                  >
                    <FontAwesomeIcon icon={faKeyboard} />
                  </button>
                </div>
              </div>
            )}

            <div
              className='
                flex w-full max-w-screen-lg
                items-center
                justify-center
              '
            >
              <div className='flex-1' />

              <RippleButton
                type='button'
                aria-label='Previous Step'
                className='
                  btn-round h-12 w-20
                  !justify-start !rounded-r-none
                  p-2
                '
                disabled={currentStepNumber <= 1}
                onClick={() => navigateToStep(previousStepNumber)}
              >
                <span>
                  <FontAwesomeIcon icon={faChevronLeft} fixedWidth size='lg' />
                </span>
              </RippleButton>

              <nav className='flex items-center'>
                <RippleButton
                  type='button'
                  className='
                    btn-round
                    z-10
                    -mx-7
                    h-12 px-6
                    py-3
                  '
                  onClick={() => setStepsMenuIsOpen(true)}
                >
                  <span className='w-16 font-semibold uppercase'>
                    Step {currentStepNumber}
                  </span>
                </RippleButton>

                <RippleButton
                  type='button'
                  aria-label='Next Step'
                  className='
                    btn-round h-12 w-20
                    !justify-end !rounded-l-none
                    p-2
                  '
                  disabled={currentStepNumber >= stepDescriptors.length}
                  onClick={() => navigateToStep(nextStepNumber)}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      fixedWidth
                      size='lg'
                    />
                  </span>
                </RippleButton>
              </nav>

              <div
                className='
                  flex
                  flex-1 items-center justify-end
                '
              >
                <a
                  type='button'
                  aria-label='Buy Me a Coffee'
                  href='https://www.buymeacoffee.com/rafaelgoodman'
                  rel='noopener noreferrer'
                  target='_blank'
                  className='btn-text-only'
                >
                  <BmcLogo className='mr-5 hidden w-32 sm:inline-block' full />

                  <BmcLogo className='mr-5 w-8 sm:hidden' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout.NonScrollableContent>
  );
}
