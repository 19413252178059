import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

const ForceInitialStepContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, () => false]);

const { Provider } = ForceInitialStepContext;

export function ForceInitialStepProvider({ children }: PropsWithChildren<{}>) {
  const [forceInitialStep, setForceInitialStep] = useState(false);

  return (
    <Provider value={[forceInitialStep, setForceInitialStep]}>
      {children}
    </Provider>
  );
}

const useForceInitialStep = () => {
  const context = useContext(ForceInitialStepContext);

  if (context === undefined) {
    throw new Error(
      'useForceInitialStep must be used withing a ForceInitialStepProvider'
    );
  }

  return context;
};

export default useForceInitialStep;
