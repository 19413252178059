import React from 'react';

export interface Props {
  className?: string;
}

export default React.memo(({ className }: Props) => (
  <svg
    className={`mt-[-.25em] inline-block ${className}`}
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    x='0px'
    y='0px'
    viewBox='0 0 49 49'
    enableBackground='new 0 0 49 49'
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.915,16.919c1.457-0.104,3.02,1.458,3.02,2.707v19.888  c0,1.146-1.562,2.812-2.812,2.812H13.071c-1.25,0-2.708-1.562-2.708-2.708V19.626c-0.208-1.146,1.875-3.02,2.916-2.707h7.914V14.42  h-8.33c-2.187,0-4.894,2.604-4.999,4.79v20.824c0,1.979,2.916,4.895,4.894,4.79h24.677c2.187,0,4.894-2.708,4.998-4.997V19.314  c0-2.187-2.707-4.894-5.103-4.894h-8.643v2.499H36.915z M25.799,2.363l-0.025-0.025C25.54,2.117,25.244,2.004,24.948,2  c-0.3,0-0.604,0.112-0.841,0.337l-0.024,0.025l-6.198,6.198c-0.475,0.475-0.483,1.232-0.025,1.691  c0.458,0.458,1.216,0.45,1.691-0.025l4.14-4.14v8.334v2.499v12.074c0,0.691,0.558,1.25,1.25,1.25c0.69,0,1.248-0.559,1.248-1.25  V16.919V14.42V6.086l4.141,4.14c0.475,0.475,1.233,0.483,1.69,0.025c0.459-0.458,0.45-1.216-0.023-1.691L25.799,2.363z   M25.773,2.337C25.548,2.113,25.249,2,24.948,2C25.244,2.004,25.54,2.117,25.773,2.337z M24.948,2  c-0.308-0.004-0.611,0.108-0.841,0.337C24.345,2.113,24.648,2,24.948,2z'
    />
  </svg>
));
