import * as Tone from 'tone';
import {
  getNoteInfo,
  getNoteLetterInfo,
  NoteLetter,
  ScaleIndex,
} from 'modules/Notes';
import assertExhaustive from './assertExhaustive';

export interface FretFingering {
  stringNumber: number;
  fretNumber: number;
}

export type Handedness = 'RIGHT' | 'LEFT';

export interface FretboardConfig {
  fretCount: number;
  stringTunings: Tone.Unit.Note[];
  fretHasSingleDot: (fretNumber: number) => boolean;
  fretHasDoubleDot: (fretNumber: number) => boolean;
}

const guitarFretHasSingleDot = (fretNumber: number) =>
  fretNumber === 3 ||
  fretNumber === 5 ||
  fretNumber === 7 ||
  fretNumber === 9 ||
  fretNumber === 15 ||
  fretNumber === 17 ||
  fretNumber === 19 ||
  fretNumber === 21;

const guitarFretHasDoubleDot = (fretNumber: number) =>
  fretNumber === 12 || fretNumber === 24;

const ukuleleFretHasSingleDot = (fretNumber: number) =>
  fretNumber === 5 || fretNumber === 7 || fretNumber === 10;

export const CommonFretboardConfigs = {
  Guitar: {
    SixString: {
      EStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['E4', 'B3', 'G3', 'D3', 'A2', 'E2'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DropD: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['E4', 'B3', 'G3', 'D3', 'A2', 'D2'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['D4', 'A3', 'F3', 'C3', 'G2', 'D2'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DropC: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['D4', 'A3', 'F3', 'C3', 'G2', 'C2'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DropB: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['Db4', 'Ab3', 'E3', 'B3', 'Gb2', 'B1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      AllFourths: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['F4', 'C4', 'G3', 'D3', 'A2', 'E2'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
    },
    SevenString: {
      BStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['E4', 'B3', 'G3', 'D3', 'A2', 'E2', 'B1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      GSharpStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['D#4', 'A#3', 'F#3', 'C#3', 'G#2', 'D#2', 'G#1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DropA: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['E4', 'B3', 'G3', 'D3', 'A2', 'E2', 'A1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      AStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['D4', 'A3', 'F3', 'C3', 'G2', 'D2', 'A1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
    },
  },
  BassGuitar: {
    FourString: {
      EStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['G2', 'D2', 'A1', 'E1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DropD: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['G2', 'D2', 'A1', 'D1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['F2', 'C2', 'G1', 'D1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
    },
    FiveString: {
      BStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['G2', 'D2', 'A1', 'E1', 'B0'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      EStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['C3', 'G2', 'D2', 'A1', 'E1'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
      DropA: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['G2', 'D2', 'A1', 'E1', 'A0'],
        fretHasSingleDot: guitarFretHasSingleDot,
        fretHasDoubleDot: guitarFretHasDoubleDot,
      },
    },
  },
  Ukulele: {
    FourString: {
      CStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['A4', 'E4', 'C4', 'G4'],
        fretHasSingleDot: ukuleleFretHasSingleDot,
        fretHasDoubleDot: () => false,
      },
      DStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['B4', 'F#4', 'D4', 'A4'],
        fretHasSingleDot: ukuleleFretHasSingleDot,
        fretHasDoubleDot: () => false,
      },
      BaritoneStandard: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['E4', 'B3', 'G3', 'D3'],
        fretHasSingleDot: ukuleleFretHasSingleDot,
        fretHasDoubleDot: () => false,
      },
      BaritoneADGC: <FretboardConfig>{
        fretCount: 12,
        stringTunings: ['B3', 'G3', 'D3', 'A2'],
        fretHasSingleDot: ukuleleFretHasSingleDot,
        fretHasDoubleDot: () => false,
      },
    },
  },
};

function addScaleIndex(scaleIndex: ScaleIndex) {
  return scaleIndex === 11 ? 0 : ((scaleIndex + 1) as ScaleIndex);
}

export function getStringNaturalNoteFingerings(
  config: FretboardConfig,
  stringNumber: number
) {
  const stringIndex = stringNumber - 1;
  if (stringIndex < 0 || stringIndex >= config.stringTunings.length) {
    throw new Error('Invalid string number.');
  }

  const ret = new Array<[NoteLetter, FretFingering]>();
  const stringTuningNote = config.stringTunings[stringIndex];
  const stringTuningNoteInfo = getNoteInfo(stringTuningNote);
  const stringTuningPitchInfo = getNoteLetterInfo(stringTuningNoteInfo.letter);

  let fretIndex = 1;
  let scaleIndex = addScaleIndex(stringTuningPitchInfo.scaleIndex);

  for (
    ;
    fretIndex <= config.fretCount;
    ++fretIndex, scaleIndex = addScaleIndex(scaleIndex)
  ) {
    let naturalNoteLetter: NoteLetter | undefined;
    switch (scaleIndex) {
      case 0:
        naturalNoteLetter = 'C';
        break;
      case 2:
        naturalNoteLetter = 'D';
        break;
      case 4:
        naturalNoteLetter = 'E';
        break;
      case 5:
        naturalNoteLetter = 'F';
        break;
      case 7:
        naturalNoteLetter = 'G';
        break;
      case 9:
        naturalNoteLetter = 'A';
        break;
      case 11:
        naturalNoteLetter = 'B';
        break;
      case 1:
      case 3:
      case 6:
      case 8:
      case 10:
        break;
      default:
        assertExhaustive(scaleIndex);
    }

    if (naturalNoteLetter) {
      ret.push([
        naturalNoteLetter,
        {
          stringNumber,
          fretNumber: fretIndex,
        },
      ]);
    }
  }

  return ret;
}

export function getNaturalNoteFingerings(config: FretboardConfig) {
  return config.stringTunings.flatMap((_, stringIndex) =>
    getStringNaturalNoteFingerings(config, stringIndex + 1)
  );
}

export function getLowestFingering(
  config: FretboardConfig,
  stringNumber: number,
  noteLetter: NoteLetter,
  aboveFretNumber: number
): FretFingering {
  const stringIndex = stringNumber - 1;
  if (stringIndex < 0 || stringIndex >= config.stringTunings.length) {
    throw new Error('Invalid string number.');
  }

  const stringTuningNote = config.stringTunings[stringIndex];
  const stringTuningNoteInfo = getNoteInfo(stringTuningNote);
  const stringTuningPitchInfo = getNoteLetterInfo(stringTuningNoteInfo.letter);

  const targetNoteFrequency = Tone.Frequency(
    `${noteLetter}${stringTuningNoteInfo.octave}` as Tone.Unit.Note
  ).transpose(
    getNoteLetterInfo(noteLetter).scaleIndex < stringTuningPitchInfo.scaleIndex
      ? 12
      : 0
  );

  const stringTuningMidiNote = Tone.Frequency(stringTuningNote).toMidi();
  const targetMidiNote = targetNoteFrequency.toMidi();
  const targetNoteFretNumber = targetMidiNote - stringTuningMidiNote;

  return {
    stringNumber,
    fretNumber:
      aboveFretNumber > -1 && targetNoteFretNumber <= aboveFretNumber
        ? targetNoteFretNumber + 12
        : targetNoteFretNumber,
  };
}

export function getLowestFingerings(
  config: FretboardConfig,
  noteLetter: NoteLetter,
  aboveFretNumber: number
): FretFingering[] {
  return config.stringTunings.map((_, stringIndex) =>
    getLowestFingering(config, stringIndex + 1, noteLetter, aboveFretNumber)
  );
}

export function getFretFingeringFrequency(
  config: FretboardConfig,
  fingering: FretFingering
) {
  const stringIndex = fingering.stringNumber - 1;
  if (stringIndex < 0 || stringIndex >= config.stringTunings.length) {
    throw new Error('Invalid string number.');
  }

  const stringTuningNote = Tone.Frequency(config.stringTunings[stringIndex]);

  return stringTuningNote.transpose(fingering.fretNumber);
}
