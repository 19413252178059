/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  useCallback,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';

import style from './style.module.scss';

function createRipple(event: React.MouseEvent<HTMLElement>) {
  const button = event.currentTarget;

  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);

  circle.style.width = `${diameter}px`;
  circle.style.height = circle.style.width;
  circle.classList.add(style.ripple);

  const ripple = button.getElementsByClassName(style.ripple)[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}

export function RippleButton({
  className,
  children,
  onClick,
  ...props
}: DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) {
  const onClickImpl = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      createRipple(event);
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <button
      type='button'
      className={`relative overflow-hidden ${className}`}
      {...props}
      onClick={onClickImpl}
    >
      {children}
    </button>
  );
}

export function RippleLink({
  className,
  to,
  children,
  onClick,
  ...props
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) {
  const onClickImpl = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      createRipple(event);
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <Link
      to={to}
      className={`relative overflow-hidden ${className}`}
      {...props}
      onClick={onClickImpl}
    >
      {children}
    </Link>
  );
}
