import {
  FretboardConfig,
  getFretFingeringFrequency,
  getLowestFingerings,
} from 'modules/Fretboard';
import { NoteLetter } from 'modules/Notes';
import * as Tone from 'tone';

export interface PlayLoopConfig {
  exerciseNoteLetters: NoteLetter[];
  fretboardConfig: FretboardConfig;
}

export type PlayLoopState = {
  beat: number;
  exerciseNoteLetters: NoteLetter[];
  noteLetterIndex: number;
  fretboardConfig: FretboardConfig;
  strings?: PlayLoopStringState;
};

export type PlayLoopStringState = {
  frequencies: Tone.FrequencyClass<number>[];
  index: number;
  pitchDirection: 'ascending' | 'descending';
};

export const getInitialLoopState = (config: PlayLoopConfig): PlayLoopState => {
  const { exerciseNoteLetters, fretboardConfig } = config;
  return {
    exerciseNoteLetters,
    beat: 0,
    noteLetterIndex: 0,
    fretboardConfig,
  };
};

const getInitialStringState = (state: PlayLoopState): PlayLoopStringState => {
  const { noteLetterIndex, exerciseNoteLetters, fretboardConfig } = state;
  const frequencies = getLowestFingerings(
    fretboardConfig,
    exerciseNoteLetters[noteLetterIndex],
    0
  ).map((fretFingering) =>
    getFretFingeringFrequency(fretboardConfig, fretFingering)
  );

  return {
    frequencies,
    index: frequencies.length - 1,
    pitchDirection: 'ascending',
  };
};

export const getNextBeatLoopState = (state: PlayLoopState): PlayLoopState => {
  const {
    exerciseNoteLetters,
    beat,
    noteLetterIndex,
    fretboardConfig,
    strings,
  } = state;

  const nextBeat = beat + 1;

  if (nextBeat < 5) {
    return {
      ...state,
      beat: nextBeat,
    };
  }

  if (!strings) {
    return {
      ...state,
      beat: nextBeat,
      strings: getInitialStringState(state),
    };
  }

  const {
    pitchDirection,
    index: stringIndex,
    frequencies: stringFrequencies,
  } = strings;

  if (pitchDirection === 'ascending') {
    const possibleNextStringIndex = stringIndex - 1;

    if (possibleNextStringIndex >= 0) {
      return {
        ...state,
        beat: nextBeat,
        strings: {
          ...strings,
          index: possibleNextStringIndex,
        },
      };
      // eslint-disable-next-line no-else-return
    } else {
      const exerciseNoteCount = exerciseNoteLetters.length;
      const nextPitchDirection = 'descending';
      const nextStringIndex = exerciseNoteCount === 1 ? 1 : 0;
      const nextNoteLetterIndex =
        noteLetterIndex < exerciseNoteCount - 1 ? noteLetterIndex + 1 : 0;
      const nextNoteLetter = exerciseNoteLetters[nextNoteLetterIndex];
      const nextStringFrequencies =
        noteLetterIndex === nextNoteLetterIndex
          ? stringFrequencies
          : getLowestFingerings(fretboardConfig, nextNoteLetter, 0).map(
              (fretFingering) =>
                getFretFingeringFrequency(fretboardConfig, fretFingering)
            );

      return {
        ...state,
        beat: nextBeat,
        noteLetterIndex: nextNoteLetterIndex,
        strings: {
          pitchDirection: nextPitchDirection,
          index: nextStringIndex,
          frequencies: nextStringFrequencies,
        },
      };
    }
  }

  const possibleNextStringIndex = stringIndex + 1;
  const stringCount = stringFrequencies.length;

  if (possibleNextStringIndex < stringCount) {
    return {
      ...state,
      beat: nextBeat,
      strings: {
        ...strings,
        index: possibleNextStringIndex,
      },
    };
    // eslint-disable-next-line no-else-return
  } else {
    const exerciseNoteCount = exerciseNoteLetters.length;
    const nextPitchDirection = 'ascending';
    const nextStringIndex =
      exerciseNoteCount === 1 ? stringCount - 2 : stringCount - 1;
    const nextNoteLetterIndex =
      noteLetterIndex < exerciseNoteCount - 1 ? noteLetterIndex + 1 : 0;
    const nextNoteLetter = exerciseNoteLetters[nextNoteLetterIndex];
    const nextStringFrequencies =
      noteLetterIndex === nextNoteLetterIndex
        ? stringFrequencies
        : getLowestFingerings(fretboardConfig, nextNoteLetter, 0).map(
            (fretFingering) =>
              getFretFingeringFrequency(fretboardConfig, fretFingering)
          );

    return {
      ...state,
      beat: nextBeat,
      noteLetterIndex: nextNoteLetterIndex,
      strings: {
        pitchDirection: nextPitchDirection,
        index: nextStringIndex,
        frequencies: nextStringFrequencies,
      },
    };
  }
};
