import React from 'react';
import { Helmet } from 'react-helmet';
import { NoteLetter } from 'modules/Notes';
import { FretboardConfig } from 'modules/Fretboard';
import StepPage from '../components/StepPage';
import FretboardPlayer, { Mode } from '../components/FretboardPlayer';

interface Props {
  canonicalUrl: string;
}

const noteLetters: NoteLetter[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

const mode: Mode = { type: 'DISPLAY_FINGERINGS' };

const instructions = (bpm: number, fretboardConfig: FretboardConfig) => (
  <div>
    <p className='mb-[.5em]'>
      <span>Play each note on&nbsp;</span>

      <strong>strings {fretboardConfig.stringTunings.length} through 1</strong>

      <span>, followed by&nbsp;</span>

      <strong>strings 2 through {fretboardConfig.stringTunings.length}</strong>

      <span> slowly </span>

      <strong>at your own pace</strong>

      <span> (no metronome) with your </span>

      <strong>first finger</strong>

      <span>.</span>
    </p>

    <p className='mb-[.5em]'>
      Don&apos;t memorize patterns. It&apos;s ok to use the chart.
    </p>

    <p>
      <span>Move on to the next note when you can play the exercise&nbsp;</span>

      <strong>3 times with no mistakes</strong>

      <span>.</span>
    </p>
  </div>
);

export default function Step1({ canonicalUrl }: Props) {
  return (
    <StepPage instructions={instructions}>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <FretboardPlayer noteLetters={noteLetters} mode={mode} />
    </StepPage>
  );
}
