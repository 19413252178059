import { NoteLetter } from 'modules/Notes';

export default function getExercises(
  noteLetters: NoteLetter[],
  exerciseNoteCount = 1
): NoteLetter[][] {
  if (exerciseNoteCount < 1 || exerciseNoteCount > noteLetters.length) {
    throw new Error(
      'The exerciseSetCount must be greater than zero and less than or equal to the noteLetters length.'
    );
  }

  if (noteLetters.length % exerciseNoteCount) {
    throw new Error(
      'The noteLetters array length must be divisible by the exerciseNoteCount.'
    );
  }

  const randomizedNoteLetters = [
    ...noteLetters.sort(() => 0.5 - Math.random()),
  ];
  const exercises: NoteLetter[][] = new Array<NoteLetter[]>();

  while (randomizedNoteLetters.length) {
    const exercise = randomizedNoteLetters.splice(0, exerciseNoteCount);
    exercises.push(exercise);
  }

  return exercises;
}
