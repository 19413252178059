import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import { CommonFretboardConfigs, Handedness } from 'modules/Fretboard';
import { useLocalStorage } from 'react-use';
import assertExhaustive from 'modules/assertExhaustive';

const { Guitar, BassGuitar, Ukulele } = CommonFretboardConfigs;

export type State = {
  lastStepNumber: number;
  bpm: number;
  fretboardConfigId: FretboardConfigId;
  handedness: Handedness;
  showFretLetters: boolean;
};

type StateKey = keyof State;

export type Action =
  | { type: 'SET_LAST_STEP_NUMBER'; lastStepNumber: number }
  | { type: 'SET_BPM'; bpm: number }
  | { type: 'SET_SHOW_FRET_LETTERS'; showFretLetters: boolean }
  | {
      type: 'SET_FRETBOARD_CONFIG';
      fretboardConfigId: FretboardConfigId;
      handedness: Handedness;
    };

export const ALL_FRETBOARD_CONFIG_IDS = [
  'GUITAR_SIX_STRING_E_STANDARD',
  'GUITAR_SIX_STRING_DROP_D',
  'GUITAR_SIX_STRING_D_STANDARD',
  'GUITAR_SIX_STRING_DROP_C',
  'GUITAR_SIX_STRING_DROP_B',
  'GUITAR_SIX_STRING_ALL_FOURTHS',
  'GUITAR_SEVEN_STRING_B_STANDARD',
  'GUITAR_SEVEN_STRING_GSHARP_STANDARD',
  'GUITAR_SEVEN_STRING_DROP_A',
  'GUITAR_SEVEN_STRING_A_STANDARD',
  'BASS_GUITAR_FOUR_STRING_E_STANDARD',
  'BASS_GUITAR_FOUR_STRING_DROP_D',
  'BASS_GUITAR_FOUR_STRING_D_STANDARD',
  'BASS_GUITAR_FIVE_STRING_B_STANDARD',
  'BASS_GUITAR_FIVE_STRING_E_STANDARD',
  'BASS_GUITAR_FIVE_STRING_DROP_A',
  'UKULELE_FOUR_STRING_C_STANDARD',
  'UKULELE_FOUR_STRING_D_STANDARD',
  'UKULELE_FOUR_STRING_BARITONE_STANDARD',
  'UKULELE_FOUR_STRING_BARITONE_ADGC',
] as const;

type FretboardIdTuple = typeof ALL_FRETBOARD_CONFIG_IDS;

export type FretboardConfigId = FretboardIdTuple[number];

const ALL_HANDEDNESS: ReadonlyArray<Handedness> = ['RIGHT', 'LEFT'] as const;

// eslint-disable-next-line consistent-return
export const getFretboardConfig = (fretboardConfigId: FretboardConfigId) => {
  switch (fretboardConfigId) {
    case 'GUITAR_SIX_STRING_E_STANDARD':
      return Guitar.SixString.EStandard;
    case 'GUITAR_SIX_STRING_DROP_D':
      return Guitar.SixString.DropD;
    case 'GUITAR_SIX_STRING_D_STANDARD':
      return Guitar.SixString.DStandard;
    case 'GUITAR_SIX_STRING_DROP_C':
      return Guitar.SixString.DropC;
    case 'GUITAR_SIX_STRING_DROP_B':
      return Guitar.SixString.DropB;
    case 'GUITAR_SIX_STRING_ALL_FOURTHS':
      return Guitar.SixString.AllFourths;
    case 'GUITAR_SEVEN_STRING_B_STANDARD':
      return Guitar.SevenString.BStandard;
    case 'GUITAR_SEVEN_STRING_GSHARP_STANDARD':
      return Guitar.SevenString.GSharpStandard;
    case 'GUITAR_SEVEN_STRING_DROP_A':
      return Guitar.SevenString.DropA;
    case 'GUITAR_SEVEN_STRING_A_STANDARD':
      return Guitar.SevenString.AStandard;
    case 'BASS_GUITAR_FOUR_STRING_E_STANDARD':
      return BassGuitar.FourString.EStandard;
    case 'BASS_GUITAR_FOUR_STRING_DROP_D':
      return BassGuitar.FourString.DropD;
    case 'BASS_GUITAR_FOUR_STRING_D_STANDARD':
      return BassGuitar.FourString.DStandard;
    case 'BASS_GUITAR_FIVE_STRING_B_STANDARD':
      return BassGuitar.FiveString.BStandard;
    case 'BASS_GUITAR_FIVE_STRING_E_STANDARD':
      return BassGuitar.FiveString.EStandard;
    case 'BASS_GUITAR_FIVE_STRING_DROP_A':
      return BassGuitar.FiveString.DropA;
    case 'UKULELE_FOUR_STRING_C_STANDARD':
      return Ukulele.FourString.CStandard;
    case 'UKULELE_FOUR_STRING_D_STANDARD':
      return Ukulele.FourString.DStandard;
    case 'UKULELE_FOUR_STRING_BARITONE_STANDARD':
      return Ukulele.FourString.BaritoneStandard;
    case 'UKULELE_FOUR_STRING_BARITONE_ADGC':
      return Ukulele.FourString.BaritoneADGC;
    default:
      assertExhaustive(fretboardConfigId);
  }
};

export const minimumBpm = 40;

const initialState: State = {
  lastStepNumber: 1,
  bpm: minimumBpm,
  fretboardConfigId: 'GUITAR_SIX_STRING_E_STANDARD',
  handedness: 'RIGHT',
  showFretLetters: false,
};

// eslint-disable-next-line consistent-return
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LAST_STEP_NUMBER':
      return { ...state, lastStepNumber: action.lastStepNumber };
    case 'SET_BPM':
      return { ...state, bpm: action.bpm };
    case 'SET_SHOW_FRET_LETTERS':
      return { ...state, showFretLetters: action.showFretLetters };
    case 'SET_FRETBOARD_CONFIG':
      return {
        ...state,
        handedness: action.handedness,
        fretboardConfigId: action.fretboardConfigId,
      };
    default:
      assertExhaustive(action);
  }
};

const parseState = (serializedValue: string) => ({
  ...initialState,
  // eslint-disable-next-line consistent-return
  ...JSON.parse(serializedValue, (key, value) => {
    if (typeof key !== 'string' || !key) {
      return value;
    }

    const stateKey = key as StateKey;
    switch (stateKey) {
      case 'lastStepNumber':
        return Number.isInteger(value) ? value : undefined;
      case 'bpm':
        return Number.isInteger(value) ? value : undefined;
      case 'fretboardConfigId':
        return typeof value === 'string' &&
          ALL_FRETBOARD_CONFIG_IDS.includes(value as FretboardConfigId)
          ? value
          : undefined;
      case 'handedness':
        return typeof value === 'string' &&
          ALL_HANDEDNESS.includes(value as Handedness)
          ? value
          : undefined;
      case 'showFretLetters':
        return typeof value === 'boolean' && !!value;
      default: {
        try {
          assertExhaustive(stateKey);
        } catch {
          return undefined;
        }
      }
    }
  }),
});

const SettingsContext = createContext<[State, Dispatch<Action>]>([
  initialState,
  () => null,
]);

const { Provider } = SettingsContext;

export function SettingsProvider({ children }: PropsWithChildren<{}>) {
  const [localStorageState, setLocalStorageState] = useLocalStorage<State>(
    'settings',
    initialState,
    {
      raw: false,
      serializer: JSON.stringify,
      deserializer: parseState,
    }
  );

  const [state, dispatch] = useReducer(
    (existingState: State, action: Action) => {
      const newState = reducer(existingState, action);
      setLocalStorageState(newState);
      return newState;
    },
    { ...(localStorageState || initialState) }
  );

  return <Provider value={[state, dispatch]}>{children}</Provider>;
}

const useSettings = () => {
  const context = useContext(SettingsContext);

  if (context === undefined) {
    throw new Error('useSettings must be used withing a SettingsProvider');
  }

  return context;
};

export default useSettings;

export const exportedForTesting = {
  initialState,
  reducer,
  ALL_FRETBOARD_CONFIG_IDS,
  parseState,
};
