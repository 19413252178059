import React from 'react';
import { Disclosure } from '@headlessui/react';
import InstallButton from 'components/InstallButton';
import LogoIcon from 'components/Logo';
import navigation from 'components/PageLayout/modules/Navigation';
import ShareButton from 'components/ShareButton';
import { Link, NavLink } from 'react-router-dom';

export default React.memo(() => (
  <Disclosure as='nav'>
    {() => (
      <div
        className='
            mx-auto
            max-w-screen-lg
            px-4
          '
      >
        <div
          className='
              relative
              flex h-16 items-center
              justify-between
            '
        >
          <div
            className='
                flex flex-1 items-center justify-center
                sm:items-center
                sm:justify-start
              '
          >
            <div className='flex-shrink-0'>
              <Link aria-label='Fretboard Forever' to='/'>
                <LogoIcon className='h-8 w-8 lg:hidden' />

                <LogoIcon className='hidden h-8 w-auto lg:inline-block' full />
              </Link>
            </div>

            <div className='hidden flex-1 text-sm sm:ml-6 sm:block'>
              <div className='flex justify-end space-x-4'>
                {navigation.map((item) => (
                  <NavLink
                    type='button'
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) => `
                        ${
                          isActive
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                        }
                        flex
                        items-center
                        whitespace-nowrap
                        rounded-md py-2 pl-3
                        pr-4
                        text-sm
                        font-medium
                        transition
                      `}
                  >
                    {({ isActive }) => (
                      <>
                        <item.icon
                          className={`
                              ${
                                isActive
                                  ? 'text-gray-300'
                                  : 'text-gray-400 group-hover:text-gray-300'
                              }
                              mr-3
                              h-6
                              w-6 flex-shrink-0
                            `}
                        />

                        <span className='flex-1'>{item.name}</span>
                      </>
                    )}
                  </NavLink>
                ))}

                <InstallButton />

                <ShareButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </Disclosure>
));
