/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import Fretboard, { useNaturalNoteFingerings } from 'components/Fretboard';
import { FretboardConfig, getLowestFingerings } from 'modules/Fretboard';
import { NoteLetter } from 'modules/Notes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faStop,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useEvent } from 'react-use';
import useSettings, { getFretboardConfig } from 'hooks/useSettings';
import useToneMetronome from 'hooks/useToneMetronome';
import * as Tone from 'tone';
import GuitarSound from 'modules/GuitarSound';
import Note from 'components/Note';
import { RippleButton } from 'components/Ripple';
import assertExhaustive from 'modules/assertExhaustive';
import { Transition, Dialog, Switch } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import style from './style.module.scss';
import {
  getInitialLoopState,
  getNextBeatLoopState,
  PlayLoopConfig,
} from './modules/PlayLoopState';
import getExercises from './modules/getExercises';

interface Props {
  noteLetters: NoteLetter[];
  mode: Mode;
}

export type Mode =
  | { type: 'EXERCISE_PLAYER'; exerciseNoteCount?: number }
  | { type: 'DISPLAY_FINGERINGS' };

type Action =
  | { type: 'START' }
  | { type: 'STOP' }
  | { type: 'START_STOP' }
  | { type: 'UPDATE_PLAY_STATE'; playState: PlayStateUpdateData }
  | { type: 'NEXT_EXERCISE' }
  | { type: 'PREVIOUS_EXERCISE' }
  | { type: 'SET_EXERCISE'; exerciseIndex: number }
  | { type: 'CLOSE_NO_METRONOME_PROMPT' };

interface ExerciseConfig {
  noteLetters: NoteLetter[];
  mode: Mode;
  fretboardConfig: FretboardConfig;
}

type PlayStateUpdateData = {
  beat: number;
  stringIndex?: number;
  noteLetterIndex: number;
};

type PlayState = {
  beat: number;
  stringIndex?: number;
};

type State = {
  exercises: NoteLetter[][];
  exerciseNoteCount: number;
  fretboardConfig: FretboardConfig;
  displayFingerings: boolean;
  showNoMetronomePrompt: boolean;
  exerciseIndex: number;
  noteLetterIndex: number;
  playLoopConfig?: PlayLoopConfig;
  playRequested: boolean;
  playState?: PlayState;
};

const getInitialState = ({
  noteLetters,
  mode,
  fretboardConfig,
}: ExerciseConfig): State => {
  const exerciseNoteCount =
    mode.type === 'EXERCISE_PLAYER' ? mode.exerciseNoteCount || 1 : 1;

  return {
    exercises: getExercises(noteLetters, exerciseNoteCount),
    exerciseNoteCount,
    fretboardConfig,
    displayFingerings: mode.type === 'DISPLAY_FINGERINGS',
    showNoMetronomePrompt: false,
    exerciseIndex: 0,
    noteLetterIndex: 0,
    playRequested: false,
  };
};

// eslint-disable-next-line consistent-return
const reducer = (state: State, action: Action): State => {
  const {
    playState,
    displayFingerings,
    exercises,
    exerciseIndex,
    fretboardConfig,
  } = state;
  const exerciseNoteLetters = exercises[exerciseIndex];
  switch (action.type) {
    case 'START_STOP':
      return reducer(state, playState ? { type: 'STOP' } : { type: 'START' });
    case 'START':
      return displayFingerings
        ? { ...state, showNoMetronomePrompt: !state.showNoMetronomePrompt }
        : {
            ...state,
            noteLetterIndex: 0,
            playLoopConfig: {
              exerciseNoteLetters,
              fretboardConfig,
            },
            playRequested: true,
            playState: undefined,
          };
    case 'STOP':
      return displayFingerings
        ? state
        : {
            ...state,
            noteLetterIndex: 0,
            playLoopConfig: undefined,
            playRequested: false,
            playState: undefined,
          };
    case 'UPDATE_PLAY_STATE':
      return state.playRequested
        ? {
            ...state,
            noteLetterIndex: action.playState.noteLetterIndex,
            playState: {
              beat: action.playState.beat,
              stringIndex: action.playState.stringIndex,
            },
          }
        : state;
    case 'NEXT_EXERCISE':
      return exercises.length === 1
        ? state
        : reducer(
            {
              ...state,
              exerciseIndex:
                exerciseIndex < exercises.length - 1 ? exerciseIndex + 1 : 0,
            },
            { type: state.playState ? 'START' : 'STOP' }
          );
    case 'PREVIOUS_EXERCISE':
      return exercises.length === 1
        ? state
        : reducer(
            {
              ...state,
              exerciseIndex:
                exerciseIndex === 0 ? exercises.length - 1 : exerciseIndex - 1,
            },
            { type: state.playState ? 'START' : 'STOP' }
          );
    case 'SET_EXERCISE':
      return exercises.length === 1
        ? state
        : reducer(
            {
              ...state,
              exerciseIndex: action.exerciseIndex,
            },
            { type: 'STOP' }
          );
    case 'CLOSE_NO_METRONOME_PROMPT':
      return { ...state, showNoMetronomePrompt: false };
    default:
      assertExhaustive(action);
  }
};

function NoMetronomeDialog({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 overflow-y-auto '
        onClose={onClose}
      >
        <div className='flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity ' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}

          <span
            className='hidden sm:inline-block sm:h-screen sm:align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='
          opacity-0
          translate-y-4 sm:translate-y-0
          sm:scale-95'
            enterTo='
          opacity-100
          translate-y-0
          sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='
          opacity-100
          translate-y-0
          sm:scale-100'
            leaveTo='
          opacity-0
          translate-y-4 sm:translate-y-0
          sm:scale-95'
          >
            <div className='inline-block transform overflow-hidden rounded-lg bg-gray-800 px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle'>
              <div className='absolute top-0 right-0 z-30 pt-4 pr-4 '>
                <button
                  type='button'
                  className='btn-text-only'
                  aria-label='Close'
                  onClick={onClose}
                >
                  <span className='sr-only'>Close</span>

                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>

              <div className='text-center'>
                <h1 className='mb-2 text-xl text-white'>
                  No need for a metronome for this step!
                </h1>

                <p className='mb-2'>
                  Just try to get the notes under your fingers.
                </p>

                <p className='mb-2'>
                  Use the fretboard chart and take it slow!
                </p>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function FretboardPlayer({ noteLetters: requestedNoteLetters, mode }: Props) {
  const guitarSound = useRef<GuitarSound>();

  const [
    { bpm, fretboardConfigId, handedness, showFretLetters },
    settingsDispatch,
  ] = useSettings();
  const naturalNoteFingerings = useNaturalNoteFingerings();

  const fretboardConfig = getFretboardConfig(fretboardConfigId);

  const [state, dispatch] = useReducer(
    reducer,
    { noteLetters: requestedNoteLetters, mode, fretboardConfig },
    getInitialState
  );

  useToneMetronome();

  useEffect(() => {
    if (!guitarSound.current) {
      guitarSound.current = new GuitarSound().toDestination();
    }

    return () => {
      guitarSound.current?.dispose();
    };
  }, []);

  const {
    playState,
    playLoopConfig,
    exerciseNoteCount,
    displayFingerings,
    exercises,
    exerciseIndex,
    noteLetterIndex,
  } = state;

  const { beat, stringIndex } = playState || {};

  const exerciseNoteLetters = exercises[exerciseIndex];
  const noteLetter = exerciseNoteLetters[noteLetterIndex];
  const showCountDown = beat && beat <= 4;

  useEffect(() => {
    Tone.Transport.bpm.value = bpm;
  }, [bpm]);

  useEffect(() => {
    let loop: Tone.Loop<Tone.LoopOptions> | undefined;

    const stop = () => {
      Tone.Transport.stop();
      Tone.Transport.position = 0;
      loop?.dispose();
    };

    (async () => {
      if (!playLoopConfig) {
        stop();
        return;
      }

      let loopState = getInitialLoopState(playLoopConfig);

      loop = new Tone.Loop((time) => {
        loopState = getNextBeatLoopState(loopState);

        if (loopState.strings) {
          const currentFrequency =
            loopState.strings.frequencies[loopState.strings.index];

          guitarSound.current?.triggerAttackRelease(
            currentFrequency.valueOf(),
            '8n',
            time
          );
        }

        Tone.Draw.schedule(() => {
          dispatch({
            type: 'UPDATE_PLAY_STATE',
            playState: {
              beat: loopState.beat,
              stringIndex: loopState.strings?.index,
              noteLetterIndex: loopState.noteLetterIndex,
            },
          });
        }, time);
      }, '4n').start(0);

      await Tone.loaded();
      Tone.Transport.start();
    })();

    return () => {
      stop();
    };
  }, [playLoopConfig]);

  useEvent('keydown', (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowRight':
      case '+':
      case 'Enter':
        dispatch({ type: 'NEXT_EXERCISE' });
        event.preventDefault();
        break;
      case 'ArrowLeft':
      case '-':
        dispatch({ type: 'PREVIOUS_EXERCISE' });
        event.preventDefault();
        break;
      case ' ':
        dispatch({ type: 'START_STOP' });
        event.preventDefault();
        break;
      default:
        break;
    }
  });

  return (
    <div
      className='
        relative
        flex
        w-full flex-col items-center
        text-gray-300
        md-h:flex-row
        md-h:justify-center
        md-h:text-2xl
      '
    >
      <NoMetronomeDialog
        show={state.showNoMetronomePrompt}
        onClose={() => dispatch({ type: 'CLOSE_NO_METRONOME_PROMPT' })}
      />

      {/* Countdown overlay */}

      <div
        className={`
          user-select-none
          fixed top-0 right-0 bottom-0 left-0
          z-50 flex items-center
          justify-center font-mono
          text-9xl
          font-extrabold
          text-gray-300
          ${
            showCountDown
              ? `
              cursor-pointer
              bg-gray-900/80
              opacity-100
            `
              : `
              duration-250
              pointer-events-none
              opacity-0
              transition
            `
          }
        `}
        onClick={() => dispatch({ type: 'STOP' })}
      >
        <div
          className='
            flex h-8 w-8
            items-center
            justify-center
            rounded-full border-[.075em]
          '
        >
          {/* Immediately make the beat invisible when playback stops so the user doesn't see it reset if they stop playback mid-countdown */}

          <span className={playState ? '' : 'invisible'}>
            {Math.max(1, Math.min(4, 5 - (beat || 0)))}
          </span>
        </div>

        <div
          key={beat}
          className={`bg-white shadow-md ${style.countInIndicator}`}
          style={{ ['--bpm' as any]: bpm }}
        />
      </div>

      {/* EXERCISE SELECTOR, NOTE INDICATOR AND PLAY BUTTON */}

      <div
        className={`
          flex w-full flex-col
          items-center
          justify-center
          text-xl
          md-h:relative
          md-h:top-6
          md-h:mb-0
          md-h:ml-10
          md-h:w-auto
          md-h:text-xs
          ${displayFingerings ? 'mb-0 sm:mb-4' : 'mb-4'}
        `}
      >
        {/* Special case: each exercise is one note letter */}

        {exerciseNoteCount === 1 && (
          <div
            className='
              relative
              flex w-full flex-col items-center
              justify-center
            '
          >
            <div
              className='
                flex max-w-[15em] flex-wrap items-center
                justify-center text-2xl
                leading-[.5em]
              '
            >
              {exercises.map((noteLetters, index) => (
                <button
                  type='button'
                  key={noteLetters.toString()}
                  className={`
                    btn-text-only
                    mx-[.125em]
                    py-[0.5em]
                    ${exerciseIndex === index ? '' : ' opacity-25'}
                  `}
                  onClick={() =>
                    dispatch({
                      type: 'SET_EXERCISE',
                      exerciseIndex: index,
                    })
                  }
                >
                  <Note note={noteLetters[0]} />
                </button>
              ))}
            </div>

            <div
              className='
                relative flex w-48
                items-center
                justify-between
              '
            >
              <button
                type='button'
                className='
                  btn-text-only
                  h-12 w-12
                '
                aria-label='Previous Note Letter'
                onClick={() => dispatch({ type: 'PREVIOUS_EXERCISE' })}
              >
                <FontAwesomeIcon icon={faChevronLeft} fixedWidth size='2x' />
              </button>

              <button
                type='button'
                className='
                  btn-text-only
                  h-4
                  basis-1/6
                  text-7xl
                  leading-[.75em]
                '
                onClick={() => dispatch({ type: 'NEXT_EXERCISE' })}
              >
                <Note note={noteLetter} />
              </button>

              <button
                type='button'
                className='
                  btn-text-only
                  h-12 w-12
                '
                aria-label='Next Note Letter'
                onClick={() => dispatch({ type: 'NEXT_EXERCISE' })}
              >
                <FontAwesomeIcon icon={faChevronRight} fixedWidth size='2x' />
              </button>
            </div>
          </div>
        )}

        {/* Multi-note exercises */}

        {exerciseNoteCount > 1 && (
          <div
            className='
              relative
              flex w-full flex-col items-center
              justify-center
            '
          >
            {/* Only show exercise switcher if there's more than one exercise */}

            {exercises.length > 1 && (
              <div
                className='
                  flex items-center justify-center
                '
              >
                <button
                  type='button'
                  className='
                    btn-text-only
                    mx-2
                  '
                  aria-label='Previous Exercise'
                  onClick={() => dispatch({ type: 'PREVIOUS_EXERCISE' })}
                >
                  <FontAwesomeIcon icon={faChevronLeft} fixedWidth size='2x' />
                </button>

                <button
                  type='button'
                  className='
                    btn-text-only
                    text-2xl
                  '
                  onClick={() => dispatch({ type: 'NEXT_EXERCISE' })}
                >
                  <span>{exerciseIndex + 1}</span>

                  <span>&nbsp;of&nbsp;</span>

                  <span>{exercises.length}</span>
                </button>

                <button
                  type='button'
                  className='
                    btn-text-only
                    mx-2
                  '
                  aria-label='Next Exercise'
                  onClick={() => dispatch({ type: 'NEXT_EXERCISE' })}
                >
                  <FontAwesomeIcon icon={faChevronRight} fixedWidth size='2x' />
                </button>
              </div>
            )}

            <div
              className={`
                flex max-w-[5em] flex-wrap items-center
                justify-center
                leading-4
                ${exerciseNoteCount > 4 ? 'text-6xl' : 'text-7xl'}
              `}
            >
              {exerciseNoteLetters.map((letter, index) => (
                <Note
                  key={letter}
                  className={`
                    mx-[0.125em]
                    ${noteLetterIndex === index ? '' : ' opacity-25'}
                  `}
                  note={letter}
                />
              ))}
            </div>
          </div>
        )}

        {!displayFingerings && (
          <RippleButton
            type='button'
            className='
              btn-round
              mt-3
              h-12 w-12
              p-2
              text-lg
            '
            aria-label={playState ? 'Stop' : 'Play'}
            onClick={() => dispatch({ type: 'START_STOP' })}
          >
            <FontAwesomeIcon icon={playState ? faStop : faPlay} fixedWidth />
          </RippleButton>
        )}
      </div>

      <div className='relative flex w-full flex-col'>
        {displayFingerings && (
          <div className='flex w-full justify-end px-[7%] sm:absolute sm:-top-14'>
            <Switch.Group
              as='li'
              className='flex items-center justify-between pb-4 sm:py-4'
            >
              <div className='mr-2 flex flex-col'>
                <Switch.Label className='cursor-pointer text-sm text-gray-500'>
                  <span>Note names</span>
                </Switch.Label>
              </div>

              <Switch
                checked={showFretLetters}
                onChange={(enable: boolean) =>
                  settingsDispatch({
                    type: 'SET_SHOW_FRET_LETTERS',
                    showFretLetters: enable,
                  })
                }
                className={`${
                  showFretLetters ? 'bg-indigo-500' : 'bg-gray-600'
                } relative z-10 inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    showFretLetters ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </Switch.Group>
          </div>
        )}

        <Fretboard
          className={`
          text-base
          md-h:-mb-12
          md-h:w-auto
          md-h:shrink-0
          md-h:basis-1/2
          ${displayFingerings ? 'md-h:ml-[1.5em]' : ''}
        `}
          config={fretboardConfig}
          handedness={handedness}
          dotOpacity={showFretLetters && displayFingerings ? 1 : undefined}
          showStringTunings={displayFingerings}
          fretFingerings={
            displayFingerings
              ? [
                  ...(showFretLetters ? naturalNoteFingerings : []),
                  ...getLowestFingerings(fretboardConfig, noteLetter, 0).map(
                    (fingering) => ({
                      ...fingering,
                      className: showFretLetters
                        ? '!text-2xl !bg-indigo-200'
                        : undefined,
                      label: (
                        <Note
                          className={`font-bold text-gray-900 ${
                            showFretLetters ? 'visible' : 'hidden'
                          }`}
                          note={noteLetter}
                        />
                      ),
                    })
                  ),
                ]
              : undefined
          }
          highlightStringNumbers={
            stringIndex !== undefined
              ? new Set<number>([stringIndex + 1])
              : undefined
          }
        />
      </div>
    </div>
  );
}

export default FretboardPlayer;
