import React from 'react';

export interface Props {
  className?: string;
}

function SharpIcon({ className }: Props) {
  return (
    <>
      <span className='copy-paste-only'>♯</span>

      <svg
        className={`
      mt-[-.2em]
      mr-[-.25em]
      mb-0
      ml-[-.2em] inline-block h-3 w-3
      ${className}
    `}
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'
        enableBackground='new 0 0 100 100'
      >
        <g>
          <polygon
            fill='none'
            stroke='currentColor'
            points='45.964,62.034 54.737,59.72 54.737,39.79 45.964,42.177  '
          />

          <path
            fill='currentColor'
            d='M64.623,37.101v-13.26l-6.027,1.592V0.216h-3.858V26.45l-8.773,2.314V3.591h-3.857V29.78l-6.729,1.775v13.502l6.729-1.83   v19.824l-6.729,1.775v13.5l6.729-1.83v23.287h3.857V75.448l8.773-2.387v23.348h3.858V72.011l6.027-1.639v-13.26l-6.027,1.59V38.741   L64.623,37.101z M54.737,59.72l-8.773,2.314V42.177l8.773-2.387V59.72z'
          />
        </g>
      </svg>
    </>
  );
}

export default SharpIcon;
