import React, { PropsWithChildren } from 'react';
import useSettings, { getFretboardConfig } from 'hooks/useSettings';
import { FretboardConfig } from 'modules/Fretboard';

export interface Props {
  instructions?: (
    bpm: number,
    fretboardConfig: FretboardConfig
  ) => JSX.Element | string;
}

export default function StepPage({
  instructions,
  children,
}: PropsWithChildren<Props>) {
  const [{ bpm, fretboardConfigId }] = useSettings();

  const fretboardConfig = getFretboardConfig(fretboardConfigId);

  return (
    <>
      {/* CONTENT */}

      <div
        className='
          relative
          flex w-full flex-col items-center
          justify-center
        '
      >
        {children}
      </div>

      {/* INSTRUCTIONS */}

      {instructions && (
        <div
          className='
            relative
            mt-4
            w-full
            px-8
            text-center
            text-lg
            md-h:mx-auto
            md-h:mt-0
            md-h:text-base
          '
        >
          {instructions(bpm, fretboardConfig)}
        </div>
      )}
    </>
  );
}
