import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import useSettings from 'hooks/useSettings';
import { getStepHref } from 'modules/StepDescriptors';
import { RippleLink } from 'components/Ripple';
import { useEvent } from 'react-use';
import * as Tone from 'tone';
import useToneMetronome from 'hooks/useToneMetronome';
import StepPage from '../components/StepPage';

interface Props {
  canonicalUrl: string;
}

const minBpm = 40;
const maxBpm = 80;

export default function Step6({ canonicalUrl }: Props) {
  const [{ bpm }, settingsDispatch] = useSettings();

  useToneMetronome();

  useEffect(() => {
    (async () => {
      await Tone.loaded();
      Tone.Transport.position = 0;
      Tone.Transport.start();
    })();

    return () => {
      Tone.Transport.stop();
    };
  }, []);

  useEffect(() => {
    Tone.Transport.bpm.value = bpm;
  }, [bpm]);

  const increaseBpm = () => {
    if (bpm < maxBpm) {
      settingsDispatch({ type: 'SET_BPM', bpm: bpm + 1 });
    }
  };

  const decreaseBpm = () => {
    if (bpm > minBpm) {
      settingsDispatch({ type: 'SET_BPM', bpm: bpm - 1 });
    }
  };

  useEvent('keydown', (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowRight':
      case '+':
        increaseBpm();
        event.preventDefault();
        break;
      case 'ArrowLeft':
      case '-':
        decreaseBpm();
        event.preventDefault();
        break;
      default:
        break;
    }
  });

  return (
    <StepPage>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <div
        className='
          flex flex-col items-center justify-center
          px-8
        '
      >
        <div
          className='
            flex w-full max-w-sm flex-col
            items-center justify-center
            text-gray-300
          '
        >
          <div className='text-8xl leading-none'>{bpm}</div>

          <div className='text-2xl'>BPM</div>

          <input
            type='range'
            className='
              mb-4 h-3
              w-full
              bg-transparent
              p-0
              focus:shadow-none
              focus:outline-none
              focus:ring-0
            '
            min={minBpm}
            max={maxBpm}
            value={bpm}
            onChange={async (e) => {
              settingsDispatch({
                type: 'SET_BPM',
                bpm: parseInt(e.target.value, 10),
              });
            }}
          />
        </div>

        <p
          className='
            mb-4
            text-center
            text-lg
          '
        >
          <strong>Increase the tempo</strong>

          <span>&nbsp;and replay&nbsp;</span>

          <strong>steps 2 through 5</strong>

          <span>.</span>
        </p>

        <RippleLink
          type='button'
          className='
            btn-round
            mb-4 px-6
            py-3
            uppercase
          '
          to={getStepHref(2)}
        >
          Go to Step 2
        </RippleLink>

        <p
          className='
            text-center
            text-lg
            md-h:max-w-lg
          '
        >
          Once you can play steps 2 through 5 at 80 BPM, congratulations! You
          know all of the notes on your fretboard! 🎉
        </p>
      </div>
    </StepPage>
  );
}
