import React from 'react';
import { Helmet } from 'react-helmet';
import PageLayout from 'components/PageLayout';
import { Link } from 'react-router-dom';
import FooterContent from 'components/FooterContent';
import moment from 'moment';
import { getStepHref } from 'modules/StepDescriptors';

interface Props {
  canonicalUrl: string;
}

const calendarFormat = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  lastWeek: '[Last] dddd',
  nextWeek: 'dddd',
  sameElse: 'dddd, LL',
};

function Article({
  title,
  date,
  children,
}: React.PropsWithChildren<{ title: string; date: Date }>) {
  const d = moment(date);
  return (
    <article className='prose prose-invert border-t-2 border-gray-700 py-9 last:mb-6 last:border-b-2'>
      <h2 className='mb-0'>{title}</h2>

      <time
        className='mt-2 block text-xs italic text-gray-400'
        dateTime={d.format('YYYY-MM-DD')}
      >
        {d.calendar(calendarFormat)}
      </time>

      {children}
    </article>
  );
}

export default function WhatsNew({ canonicalUrl }: Props) {
  return (
    <PageLayout.ScrollableContent>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <div className='flex min-h-full flex-col py-6'>
        <section className='prose prose-invert flex-1'>
          <h1>What&apos;s New</h1>

          <Article title='Interactive Fretboard' date={new Date('2024-06-30')}>
            <p>
              Fretboard Forever works best if you practice using your actual
              instrument, but if you&apos;re in a pinch, you can now interact
              with the on-screen fretboard.
            </p>

            <p>
              For smaller devices, works best if you rotate your screen to
              landscape mode.
            </p>
          </Article>

          <Article
            title='Added Baritone Ukelele tunings'
            date={new Date('2024-06-29')}
          >
            <p>
              <span>
                You can now select baritone ukelele tunings from the&nbsp;
              </span>

              <Link to='/settings'>Settings</Link>

              <span>&nbsp;page. Thanks for the suggestion, Maximus!</span>
            </p>
          </Article>

          <Article
            title='Audio When Switching to Silent Mode on iOS'
            date={new Date('2023-09-26')}
          >
            <p>
              <span>
                Audio will now work when switching to silent mode on iOS. Thanks
                for reporting the issue, Jay!
              </span>
            </p>
          </Article>

          <Article
            title='Improved Note Exercise Workflow'
            date={new Date('2023-09-26')}
          >
            <p>
              <span>
                Playback will now continue when switching between notes within a
                step.
              </span>
            </p>
          </Article>

          <Article
            title='6-String Guitar Drop B and 7-String Guitar G# Standard Tunings'
            date={new Date('2023-08-07')}
          >
            <p>
              <span>
                You can now select <em>Drop B</em> tuning for 6-string guitars
                and <em>G# Standard</em> tuning for 7-string guitars from
                the&nbsp;
              </span>

              <Link to='/settings'>Settings</Link>

              <span>&nbsp;page.</span>
            </p>
          </Article>

          <Article
            title='Fixed Bug Where Step 3 Was Missing a Note'
            date={new Date('2023-02-02')}
          >
            <p>
              <Link to={getStepHref(3)}>Step 3</Link>

              <span> was missing C#. 😳</span>
            </p>
          </Article>

          <Article title='Step 1 Fret Note Names' date={new Date('2022-12-10')}>
            <p>
              <span>
                Added an option to show fret note names when practicing{' '}
              </span>

              <Link to={getStepHref(1)}>Step 1</Link>

              <span>.</span>
            </p>
          </Article>

          <Article title='Improved Instructions' date={new Date('2022-04-03')}>
            <p>
              <span>Clarification that the metronome isn&apos;t used for </span>

              <Link to={getStepHref(1)}>Step 1</Link>

              <span>
                {' '}
                since the goal there is to just get the notes under your
                fingers.
              </span>
            </p>

            <p>
              Additionally, each set of step instructions now clarify that you
              should use your first finger to play each note to keep the focus
              on memorizing the notes instead of having to worry about fingering
              technique, too.
            </p>
          </Article>

          <Article
            title='Improved Step Navigation'
            date={new Date('2022-02-11')}
          >
            <p>
              Step navigation buttons now become disabled when at the first and
              last steps and include button press animations.
            </p>
          </Article>

          <Article title='Ukulele Support' date={new Date('2022-02-10')}>
            <p>
              You can now learn the notes on the ukulele fretboard with C and D
              standard tunings. 🤙🌺
            </p>

            <p>
              <span>
                Just select <em>Ukulele</em> on the&nbsp;
              </span>

              <Link to='/settings'>Settings</Link>

              <span>&nbsp;page.</span>
            </p>
          </Article>

          <Article
            title='6-String Guitar Drop C Tuning'
            date={new Date('2022-02-03')}
          >
            <p>
              <span>
                You can now select <em>Drop C</em> tuning for 6-string guitars
                from the&nbsp;
              </span>

              <Link to='/settings'>Settings</Link>

              <span>&nbsp;page.</span>
            </p>
          </Article>
        </section>

        <footer className='grow-0'>
          <FooterContent />
        </footer>
      </div>
    </PageLayout.ScrollableContent>
  );
}
