import React from 'react';
import { Helmet } from 'react-helmet';
import { NoteLetter } from 'modules/Notes';
import { FretboardConfig } from 'modules/Fretboard';
import StepPage from '../components/StepPage';
import FretboardPlayer, { Mode } from '../components/FretboardPlayer';

interface Props {
  canonicalUrl: string;
}

const noteLetters: NoteLetter[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

const mode: Mode = {
  type: 'EXERCISE_PLAYER',
  exerciseNoteCount: noteLetters.length,
};

const instructions = (bpm: number, fretboardConfig: FretboardConfig) => (
  <div>
    <span>Play natural notes&nbsp;</span>

    <strong>nonstop</strong>

    <span>, alternating one note on&nbsp;</span>

    <strong>strings {fretboardConfig.stringTunings.length} through 1</strong>

    <span>&nbsp;and another on&nbsp;</span>

    <strong>strings 1 through {fretboardConfig.stringTunings.length}</strong>

    <span>, with&nbsp;</span>

    <strong>no mistakes</strong>

    <span>&nbsp;at&nbsp;</span>

    <strong>{bpm} BPM</strong>

    <span> with your </span>

    <strong>first finger</strong>

    <span>.</span>
  </div>
);

export default function Step5({ canonicalUrl }: Props) {
  return (
    <StepPage instructions={instructions}>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <FretboardPlayer noteLetters={noteLetters} mode={mode} />
    </StepPage>
  );
}
