export interface StepDescriptor {
  number: number;
  summary: (bpm: number) => string;
}

const stepDescriptors: StepDescriptor[] = [
  {
    summary: () => 'Play natural notes on each string at your own pace.',
  },
  {
    summary: (bpm: number) =>
      `Play natural notes on each string at ${bpm} BPM.`,
  },
  {
    summary: (bpm: number) =>
      `Play accidental notes on each string at ${bpm} BPM.`,
  },
  {
    summary: (bpm: number) =>
      `Play two consecutive notes, one ascending, another descending, at ${bpm} BPM.`,
  },
  {
    summary: (bpm: number) =>
      `Play natural notes on each string nonstop at ${bpm} BPM.`,
  },
  {
    summary: () => 'Increase the tempo.',
  },
].map((step, index) => {
  const stepNumber = index + 1;
  return {
    number: stepNumber,
    summary: step.summary,
  };
});

export default stepDescriptors;

export const getStepHref = (stepNumber: number) =>
  `/practice/steps/${stepNumber}`;
