import React from 'react';
import { ShareIcon } from '@heroicons/react/outline';
import useInstall from 'hooks/useInstall';

export interface Props {
  className?: string;
}

export default React.memo(({ className }: Props) => {
  const { isInstalled } = useInstall();

  if (!isInstalled || !navigator.share || !navigator.canShare) {
    return null;
  }

  return (
    <button
      type='button'
      className={`btn-text-only ${className}`}
      aria-label='Share'
      onClick={() =>
        navigator.share({
          url: '/',
        })
      }
    >
      <ShareIcon className='h-4 w-4' />
    </button>
  );
});
