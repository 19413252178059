import * as Tone from 'tone';
import click from 'assets/audio/click.wav';
import { useEffect } from 'react';

const volume = 15;
const clickNote = 'C4';
const clickInterval = '4n';

const sampler = new Tone.Sampler({ urls: { [clickNote]: click } }).connect(
  new Tone.Volume(volume).toDestination()
);

const useMetronome = () => {
  useEffect(() => {
    const loop = new Tone.Loop((time) => {
      sampler.triggerAttack(clickNote, time);
    }, clickInterval);

    loop.start(0);

    return () => {
      loop.dispose();
    };
  }, []);
};

export default useMetronome;
