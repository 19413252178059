import { AccidentalSymbol, getNoteLetterInfo, NoteLetter } from 'modules/Notes';
import React from 'react';
import FlatIcon from 'components/FlatIcon';
import SharpIcon from 'components/SharpIcon';
import assertExhaustive from 'modules/assertExhaustive';

export interface Props {
  className?: string;
  note: NoteLetter;
}

function Accidental({ symbol }: { symbol: AccidentalSymbol }) {
  switch (symbol) {
    case 'double flat':
      return (
        <>
          <FlatIcon />

          <FlatIcon />
        </>
      );
    case 'flat':
      return <FlatIcon />;
    case 'natural':
      return null;
    case 'sharp':
      return <SharpIcon />;
    case 'double sharp':
      return (
        <>
          <FlatIcon />

          <FlatIcon />
        </>
      );
    default:
      assertExhaustive(symbol);
  }
}

export default React.memo(({ note, className }: Props) => {
  const noteLetterInfo = getNoteLetterInfo(note);
  return (
    <span className={`whitespace-nowrap ${className}`}>
      {noteLetterInfo.letter}

      <Accidental symbol={noteLetterInfo.symbol} />
    </span>
  );
});
