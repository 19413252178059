import React from 'react';
import { Helmet } from 'react-helmet';
import Logo from 'components/Logo';
import PageLayout from 'components/PageLayout';
import { Link } from 'react-router-dom';
import FooterContent from 'components/FooterContent';

interface Props {
  canonicalUrl: string;
}

export default function About({ canonicalUrl }: Props) {
  return (
    <PageLayout.ScrollableContent>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <div
        className='
          flex h-full flex-col items-center
          justify-between
          py-5
        '
      >
        {/* This element is to trick the browser to vertically centering the element after given that the parent has justify-between */}

        <div aria-hidden='true' />

        <div className='flex flex-col items-center justify-center'>
          <Logo className='h-32 w-32' />

          <h1
            className='
              inline-block
              text-2xl
              font-extrabold
              text-white
            '
          >
            Fretboard Forever
          </h1>

          <h2
            className='
              mb-3
              inline-block
              text-center
              text-base
              font-semibold
              text-gray-100
              md:max-w-md
              lg:max-w-lg
            '
          >
            Learn the notes on your fretboard so they become second
            nature...forever!
          </h2>
        </div>

        <div
          className='
            flex max-w-sm flex-col
            justify-center
            leading-normal
            md:max-w-md
            lg:max-w-lg
            lg:text-sm
            xl:max-w-xl
          '
        >
          <p className='mb-3'>
            Learning the notes of the fretboard is foundational to unlocking
            musical freedom on your instrument.
          </p>

          <p className='mb-3'>
            <span>
              Fretboard Forever guides you through the daunting task using a set
              of actionable and measurable goals to instinctively knowing the
              notes of the fretboard as described by&nbsp;
            </span>

            <a
              href='https://youtu.be/PJddQ6Q0UDo'
              rel='noopener noreferrer'
              target='_blank'
            >
              Tommaso Zillio
            </a>

            <span>.</span>
          </p>

          <p className='mb-3'>
            <span>
              Follow the steps in order, resisting the temptation to increase
              the tempo too quickly, for 5 minutes a day. You&apos;ll be amazed
              at the progress you make!
            </span>
          </p>
        </div>

        <div className='flex items-center justify-center'>
          <Link
            type='button'
            className='btn-normal my-5 mx-auto'
            to='/practice'
          >
            <div className='-my-4 flex items-center justify-center'>
              <div className='mr-0.5 text-4xl'>🤘</div>

              <div className='flex flex-col items-center justify-center font-bold leading-normal'>
                <div> Grab Your Axe!</div>

                <div>Let&apos;s Do This!</div>
              </div>
            </div>
          </Link>
        </div>

        <footer className='grow-0'>
          <FooterContent />
        </footer>
      </div>
    </PageLayout.ScrollableContent>
  );
}
