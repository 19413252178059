/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Portal, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

function ServiceWorkerRegistration() {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    (window.location as any).reload(true); // See https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
  };

  return (
    <Portal>
      <div
        aria-live='assertive'
        className='pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6'
      >
        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

          <Transition
            show={showReload}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div
              className='pointer-events-auto w-full max-w-sm cursor-pointer overflow-hidden rounded-lg bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5'
              onClick={reloadPage}
            >
              <div className='p-4'>
                <div className='flex items-center'>
                  <div className='flex w-0 flex-1 justify-between'>
                    <p className='w-0 flex-1 text-sm font-medium text-gray-900'>
                      🎉 A new version is available!
                    </p>

                    <button
                      type='button'
                      className='btn-text-only ml-3 flex-shrink-0 !text-sm !font-medium !text-indigo-600 hover:!text-indigo-500'
                      onClick={reloadPage}
                    >
                      Reload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Portal>
  );
}

export default ServiceWorkerRegistration;
