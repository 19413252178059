import React from 'react';

export interface Props {
  className?: string;
}

export default React.memo(({ className }: Props) => (
  <>
    <span className='copy-paste-only'>♭</span>

    <svg
      className={`
          mt-[-.2em]
          mr-[-.25em]
          mb-0
          ml-[-.2em] inline-block h-3 w-3
          ${className}
        `}
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      enableBackground='new 0 0 100 100'
    >
      <path
        fill='currentColor'
        d='M37.06,57.826c0-19.097-0.002-38.104-0.002-57.202h-6.126c0,32.51,0.005,66.241,0.005,98.75  c4.819-2.662,10.279-7.855,17.057-12.246c8.402-5.439,19.966-10.391,20.993-20.555C70.688,49.763,45.366,51.006,37.06,57.826z   M37.06,88.879c0-8.018,0-16.035,0-24.055c3.736-7.363,16.521-7.342,17.494,0.875C55.793,76.17,41.842,83.719,37.06,88.879z'
      />
    </svg>
  </>
));
