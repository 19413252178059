/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MenuIcon } from '@heroicons/react/outline';
import LogoIcon from 'components/Logo';
import { Link } from 'react-router-dom';
import useInstall, { IosInstallPromptModal } from 'hooks/useInstall';
import useCompensationForMobileViewportHeight from 'hooks/useCompensationForMobileViewportHeight';
import ShareButton from 'components/ShareButton';
import SideNavBar from './components/SideNavBar';
import TopNavBar from './components/TopNavBar';

const ContentIsNonScrollableContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, () => undefined]);

function ContentIsNonScrollableProvider({ children }: PropsWithChildren<{}>) {
  const state = useState(false);
  return (
    <ContentIsNonScrollableContext.Provider value={state}>
      {children}
    </ContentIsNonScrollableContext.Provider>
  );
}

const useNonScrollableContentLayout = () => {
  const context = useContext(ContentIsNonScrollableContext);

  if (context === undefined) {
    throw new Error(
      'useNonScrollableContentLayout must be used within a PageLayout'
    );
  }

  const [, setContentIsNonScrollable] = context;

  useEffect(() => {
    setContentIsNonScrollable(true);

    return () => {
      setContentIsNonScrollable(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

function PageLayout({ children }: PropsWithChildren<{}>) {
  const [sideNavIsOpen, setSideNavIsOpen] = useState(false);

  const [contentIsNonScrollable] = useContext(ContentIsNonScrollableContext);

  const { isInstalled, iosInstallPromptModalProps } = useInstall();

  const onSideBarClose = useCallback(
    () => setSideNavIsOpen(false),
    [setSideNavIsOpen]
  );

  return (
    <div
      className={`
        md:text-auto-size
        relative
        flex
        h-screen
        text-[1rem]
        sm-h:!text-[.88rem]
        ${isInstalled ? 'user-select-none' : ''}
      `}
    >
      {
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        <IosInstallPromptModal {...iosInstallPromptModalProps} />
      }

      <SideNavBar open={sideNavIsOpen} onClose={onSideBarClose} />

      <div
        className='
          flex
          flex-1 flex-col
        '
      >
        <div
          className={`
            flex
            bg-gray-800
            sm-h:!h-auto
            ${contentIsNonScrollable ? 'relative' : 'fixed left-0 right-0 z-10'}
          `}
        >
          <button
            type='button'
            className={`
              btn-text-only z-10
              my-1.5
              ml-1.5
              text-3xl
              sm:!hidden
              ${
                contentIsNonScrollable
                  ? `
                    sm-h:absolute
                    sm-h:z-10
                    sm-h:!inline-flex
                    sm-h:text-3xl`
                  : ''
              }
            `}
            aria-label='Menu'
            onClick={() => setSideNavIsOpen(!sideNavIsOpen)}
          >
            <MenuIcon className='h-5 w-5' />
          </button>

          <div className='flex-1' onClick={() => setSideNavIsOpen(false)}>
            <div
              className={`
                hidden
                sm:block
                ${contentIsNonScrollable ? 'sm-h:!hidden' : ''}
              `}
            >
              <TopNavBar />
            </div>
          </div>

          <div
            className='
              absolute
              inset-0
              flex
              items-center
              justify-center
              sm:hidden
            '
            onClick={() => setSideNavIsOpen(false)}
          >
            <Link
              type='button'
              aria-label='Fretboard Forever'
              to='/'
              className={`transition sm:hidden ${
                sideNavIsOpen ? 'pointer-events-none opacity-0' : 'opacity-100'
              } ${contentIsNonScrollable ? 'sm-h:!hidden' : ''}`}
            >
              <LogoIcon full className='h-8 w-auto' />
            </Link>

            <ShareButton className='absolute right-3 text-2xl' />
          </div>
        </div>

        <div
          className='
            relative
            flex
            w-full flex-1
            justify-center
          '
          onClick={() => setSideNavIsOpen(false)}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

function PageLayoutWithProvider({ children }: PropsWithChildren<{}>) {
  return (
    <ContentIsNonScrollableProvider>
      <PageLayout>{children}</PageLayout>
    </ContentIsNonScrollableProvider>
  );
}

function NonScrollableContent({ children }: PropsWithChildren<{}>) {
  useNonScrollableContentLayout();
  useCompensationForMobileViewportHeight();

  return <div className='h-full w-full'>{children}</div>;
}

function ScrollableContent({ children }: PropsWithChildren<{}>) {
  return <div className='max-w-screen-lg  px-4 pb-0 pt-16'>{children}</div>;
}

PageLayoutWithProvider.NonScrollableContent = NonScrollableContent;
PageLayoutWithProvider.ScrollableContent = ScrollableContent;

export default PageLayoutWithProvider;
