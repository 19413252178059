import { useEffect } from 'react';
import { useEvent } from 'react-use';
import useInstall from './useInstall';

const setVhProperty = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const removeVhProperty = () => {
  document.documentElement.style.removeProperty('--vh');
};

const useCompensationForMobileViewportHeight = () => {
  const { isInstalled } = useInstall();

  useEffect(() => {
    if (isInstalled) {
      removeVhProperty();
    } else {
      setVhProperty();
    }
  }, [isInstalled]);

  useEvent('resize', () => {
    if (isInstalled) {
      return;
    }

    setVhProperty();
  });

  useEvent('touchmove', removeVhProperty);
};

export default useCompensationForMobileViewportHeight;
