import React from 'react';
import InstallButton from 'components/InstallButton';
import LogoIcon from 'components/Logo';
import navigation from 'components/PageLayout/modules/Navigation';
import { Link, NavLink } from 'react-router-dom';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default React.memo(({ open, onClose }: Props) => (
  <div
    className={`
        fixed
        inset-y-0
        z-20
        -translate-x-full
        transition-transform duration-200
        sm:-translate-x-full
        ${
          open
            ? 'translate-x-0 shadow-[0_0_10px_5px_rgba(0,0,0,0.1)] sm-h:!translate-x-0'
            : ''
        }
      `}
  >
    <div
      className='
          flex h-full flex-1
          flex-col
          bg-gray-800
          text-xl
        '
    >
      <div
        className='
            flex flex-col
            overflow-y-auto
            pt-5
          '
      >
        <Link
          type='button'
          aria-label='Fretboard Forever'
          className='
              flex flex-shrink-0
              items-center
              px-4
            '
          to='/'
          onClick={onClose}
        >
          <LogoIcon className='h-6 w-auto text-xs' full />
        </Link>

        <nav
          className='
              mt-5
              flex-1
              space-y-1
              bg-gray-800
              px-2
            '
          aria-label='Sidebar'
        >
          {navigation.map((item) => (
            <NavLink
              type='button'
              key={item.name}
              to={item.href}
              onClick={onClose}
              className={({ isActive }) => `
                  ${
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  }
                  group
                  flex
                  items-center rounded-md
                  px-2 py-2
                  text-sm
                  font-medium
                  transition
                `}
            >
              {({ isActive }) => (
                <>
                  <item.icon
                    className={`
                        ${
                          isActive
                            ? 'text-gray-300'
                            : 'text-gray-400 group-hover:text-gray-300'
                        }
                        mr-3
                        h-6
                        w-6 flex-shrink-0
                      `}
                  />

                  <span className='flex-1'>{item.name}</span>
                </>
              )}
            </NavLink>
          ))}
        </nav>
      </div>

      <InstallButton className='m-5' onClick={onClose} />
    </div>
  </div>
));
