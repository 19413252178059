import React, {
  createContext,
  Fragment,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import Div100vh from 'react-div-100vh';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Logo from 'components/Logo';
import SafariShareIcon from 'components/SafariShareIcon';
import { useEvent } from 'react-use';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

type State = {
  isInstalled: boolean;
  installPrompt?: () => void;
  iosInstallPromptModalProps: IosInstallPromptModalProps;
};

const getIsInstalled = () => {
  if (document.referrer.startsWith('android-app://')) {
    return true;
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  }

  if ((navigator as any).standalone) {
    return true;
  }

  return false;
};

interface IosInstallPromptModalProps {
  open: boolean;
  onClose: () => void;
}

export function IosInstallPromptModal({
  open,
  onClose,
}: IosInstallPromptModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 overflow-y-auto'
        onClose={onClose}
      >
        <Div100vh>
          <div className='flex h-full items-end justify-center p-4 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <span
              className='hidden sm:inline-block sm:h-screen sm:align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div
                className='
                sm
                :align-middle
                relative
                inline-block
                transform
                overflow-hidden
                rounded-lg bg-gray-800 px-4
                pt-5
                pb-4
                text-left
                align-bottom
                shadow-xl
                transition-all
                sm:w-full sm:max-w-sm
                sm:p-6
              '
              >
                <div
                  className='
                  absolute
                  top-0 right-0 z-30
                  pt-4 pr-4
                '
                >
                  <button
                    type='button'
                    className='btn-text-only'
                    aria-label='Close'
                    onClick={onClose}
                  >
                    <span className='sr-only'>Close</span>

                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>

                <div>
                  <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-900'>
                    <Logo className='h-6 w-6' aria-hidden='true' />
                  </div>

                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-100'
                    >
                      Install Fretboard Forever
                    </Dialog.Title>

                    <div className='mt-2'>
                      <p className='text-sm text-gray-400'>
                        Install this application on your home screen so it can
                        run full screen and without an internet connection.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='mt-5 text-center sm:mt-6'>
                  Just tap <SafariShareIcon className='h-8 w-8' /> then
                  &apos;Add to Home Screen&apos;
                </div>
              </div>
            </Transition.Child>
          </div>
        </Div100vh>
      </Dialog>
    </Transition.Root>
  );
}

const getIsIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const InstallContext = createContext<State>({
  isInstalled: false,
  installPrompt: undefined,
  iosInstallPromptModalProps: { open: false, onClose: () => undefined },
});

const { Provider } = InstallContext;

export function InstallProvider({ children }: PropsWithChildren<{}>) {
  const [state, setState] = useState<State>({
    isInstalled: getIsInstalled(),
    installPrompt: undefined,
    iosInstallPromptModalProps: {
      open: false,
      onClose: () => undefined,
    },
  });

  const normalInstallPrompt =
    (beforeInstallPromptEvent: BeforeInstallPromptEvent) => () => {
      setState((previousState) => ({
        ...previousState,
        installPrompt: undefined,
      }));

      (async () => {
        beforeInstallPromptEvent.prompt();
        await beforeInstallPromptEvent.userChoice;
      })();
    };

  const { isInstalled } = state;

  useEffect(() => {
    if (!getIsIos() || isInstalled) {
      return;
    }

    const iosInstallPrompt = () =>
      setState((previousState) => ({
        ...previousState,
        iosInstallPromptModalProps: {
          ...previousState.iosInstallPromptModalProps,
          open: true,
        },
      }));

    const closeIosInstallPrompt = () => {
      setState((previousState) => ({
        ...previousState,
        iosInstallPromptModalProps: {
          ...previousState.iosInstallPromptModalProps,
          open: false,
        },
      }));
    };

    setState((previousState) => ({
      ...previousState,
      installPrompt: iosInstallPrompt,
      iosInstallPromptModalProps: {
        ...previousState.iosInstallPromptModalProps,
        open: false,
        onClose: closeIosInstallPrompt,
      },
    }));
  }, [isInstalled]);

  useEvent(
    'beforeinstallprompt',
    (beforeInstallPromptEvent: BeforeInstallPromptEvent) => {
      beforeInstallPromptEvent.preventDefault();
      setState((previousState) => ({
        ...previousState,
        installPrompt: normalInstallPrompt(beforeInstallPromptEvent),
      }));
    }
  );

  useEvent('appinstalled', () => {
    setState((previousState) => ({
      ...previousState,
      isInstalled: true,
      installPrompt: undefined,
    }));
  });

  return <Provider value={state}>{children}</Provider>;
}

const useInstall = () => {
  const context = useContext(InstallContext);

  if (context === undefined) {
    throw new Error('useInstall must be used within a InstallContext');
  }

  return context;
};

export default useInstall;
