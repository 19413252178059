import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faYoutube,
  faSpotify,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import useInstall from 'hooks/useInstall';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import BmcLogo from 'components/BmcLogo';

export interface Props {
  className?: string;
}

export default function FooterContent({ className }: Props) {
  const { isInstalled } = useInstall();

  return (
    <div
      className={`
       ${className}
        flex
        flex-col items-center justify-center
        lg:text-sm
      `}
    >
      <div className=' mb-2 inline-flex items-center justify-center'>
        <span>Made with</span>

        <FontAwesomeIcon
          className='mx-1 text-red-500'
          icon={faHeart}
          fixedWidth
        />

        <span className='copy-paste-only'>❤️</span>

        <span>by Rafael</span>
      </div>

      <div className='mb-2 inline-flex justify-evenly text-2xl'>
        <a
          type='button'
          aria-label='LinkedIn'
          href='https://open.spotify.com/artist/0ujOvR57vwzq1kI1sarRCT?si=GXJzHKySSOqfKMWCR5Yf_g'
          rel='noopener noreferrer'
          target='_blank'
          className='btn-text-only mr-2 text-inherit'
        >
          <FontAwesomeIcon icon={faSpotify} />
        </a>

        <a
          type='button'
          aria-label='Instagram'
          href='https://www.instagram.com/rafael.d.goodman'
          rel='noopener noreferrer'
          target='_blank'
          className='btn-text-only mr-2 text-inherit'
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>

        <a
          type='button'
          aria-label='YouTube'
          href='https://www.youtube.com/@rafd123'
          rel='noopener noreferrer'
          target='_blank'
          className='btn-text-only mr-2 text-inherit'
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>

        <a
          type='button'
          aria-label='LinkedIn'
          href='https://www.linkedin.com/in/rafael-goodman/'
          rel='noopener noreferrer'
          target='_blank'
          className='btn-text-only mr-2 text-inherit'
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>

        <a
          type='button'
          aria-label='Buy Me a Coffee'
          href='https://www.buymeacoffee.com/rafaelgoodman'
          rel='noopener noreferrer'
          target='_blank'
          className='btn-text-only text-inherit'
        >
          <BmcLogo className='mr-[-.5em] h-4 w-4' />
        </a>
      </div>

      <div className='text-sm'>
        <span>{isInstalled ? 'App' : 'Site'}</span>

        <span>&nbsp;Design ©️&nbsp;</span>

        <span>{new Date().getFullYear()}</span>

        <span>&nbsp;RocketWing, LLC</span>
      </div>

      <div
        className='
          mt-1
          text-[.65em]
        '
      >
        Ver. {process.env.REACT_APP_GIT_SHA}
      </div>
    </div>
  );
}
