import React, { Fragment, useCallback, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { Listbox, RadioGroup, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  CheckIcon,
  SelectorIcon,
} from '@heroicons/react/outline';
import Fretboard, { useNaturalNoteFingerings } from 'components/Fretboard';
import PageLayout from 'components/PageLayout';
import useForceInitialStep from 'hooks/useForceInitialStep';
import useSettings, {
  ALL_FRETBOARD_CONFIG_IDS,
  FretboardConfigId,
  getFretboardConfig,
  minimumBpm,
} from 'hooks/useSettings';
import assertExhaustive from 'modules/assertExhaustive';
import { Handedness as FretboardHandedness } from 'modules/Fretboard';
import { useNavigate } from 'react-router-dom';

interface Props {
  canonicalUrl: string;
}

interface NamedChoice {
  name: string;
}

interface Handedness extends NamedChoice {
  name: string;
  handedness: FretboardHandedness;
}

interface Tuning extends NamedChoice {
  name: string;
  fretboardConfigId: FretboardConfigId;
}

interface StringCount extends NamedChoice {
  name: string;
  tunings: Array<Tuning>;
}

interface Instrument extends NamedChoice {
  name: string;
  stringCounts: Array<StringCount>;
}

const handednesses: Array<Handedness> = [
  {
    name: 'Right Handed',
    handedness: 'RIGHT',
  },
  {
    name: 'Left Handed',
    handedness: 'LEFT',
  },
];

interface FretboardConfigDescription {
  instrumentName: string;
  stringCount: number;
  tuningName: string;
  fretboardConfigId: FretboardConfigId;
}

const getFretboardConfigDescription = (
  fretboardConfigId: FretboardConfigId
  // eslint-disable-next-line consistent-return
): FretboardConfigDescription => {
  switch (fretboardConfigId) {
    case 'GUITAR_SIX_STRING_E_STANDARD':
      return {
        instrumentName: 'Guitar',
        stringCount: 6,
        tuningName: 'E Standard',
        fretboardConfigId,
      };
    case 'GUITAR_SIX_STRING_DROP_D':
      return {
        instrumentName: 'Guitar',
        stringCount: 6,
        tuningName: 'Drop D',
        fretboardConfigId,
      };
    case 'GUITAR_SIX_STRING_D_STANDARD':
      return {
        instrumentName: 'Guitar',
        stringCount: 6,
        tuningName: 'D Standard',
        fretboardConfigId,
      };
    case 'GUITAR_SIX_STRING_DROP_C':
      return {
        instrumentName: 'Guitar',
        stringCount: 6,
        tuningName: 'Drop C',
        fretboardConfigId,
      };
    case 'GUITAR_SIX_STRING_DROP_B':
      return {
        instrumentName: 'Guitar',
        stringCount: 6,
        tuningName: 'Drop B',
        fretboardConfigId,
      };
    case 'GUITAR_SIX_STRING_ALL_FOURTHS':
      return {
        instrumentName: 'Guitar',
        stringCount: 6,
        tuningName: 'All 4ths',
        fretboardConfigId,
      };
    case 'GUITAR_SEVEN_STRING_B_STANDARD':
      return {
        instrumentName: 'Guitar',
        stringCount: 7,
        tuningName: 'B Standard',
        fretboardConfigId,
      };
    case 'GUITAR_SEVEN_STRING_GSHARP_STANDARD':
      return {
        instrumentName: 'Guitar',
        stringCount: 7,
        tuningName: 'G# Standard',
        fretboardConfigId,
      };
    case 'GUITAR_SEVEN_STRING_DROP_A':
      return {
        instrumentName: 'Guitar',
        stringCount: 7,
        tuningName: 'Drop A',
        fretboardConfigId,
      };
    case 'GUITAR_SEVEN_STRING_A_STANDARD':
      return {
        instrumentName: 'Guitar',
        stringCount: 7,
        tuningName: 'A Standard',
        fretboardConfigId,
      };
    case 'BASS_GUITAR_FOUR_STRING_E_STANDARD':
      return {
        instrumentName: 'Bass',
        stringCount: 4,
        tuningName: 'E Standard',
        fretboardConfigId,
      };
    case 'BASS_GUITAR_FOUR_STRING_DROP_D':
      return {
        instrumentName: 'Bass',
        stringCount: 4,
        tuningName: 'Drop D',
        fretboardConfigId,
      };
    case 'BASS_GUITAR_FOUR_STRING_D_STANDARD':
      return {
        instrumentName: 'Bass',
        stringCount: 4,
        tuningName: 'D Standard',
        fretboardConfigId,
      };
    case 'BASS_GUITAR_FIVE_STRING_B_STANDARD':
      return {
        instrumentName: 'Bass',
        stringCount: 5,
        tuningName: 'B Standard',
        fretboardConfigId,
      };
    case 'BASS_GUITAR_FIVE_STRING_E_STANDARD':
      return {
        instrumentName: 'Bass',
        stringCount: 5,
        tuningName: 'E Standard',
        fretboardConfigId,
      };
    case 'BASS_GUITAR_FIVE_STRING_DROP_A':
      return {
        instrumentName: 'Bass',
        stringCount: 5,
        tuningName: 'Drop A',
        fretboardConfigId,
      };
    case 'UKULELE_FOUR_STRING_C_STANDARD':
      return {
        instrumentName: 'Ukulele',
        stringCount: 4,
        tuningName: 'C Standard',
        fretboardConfigId,
      };
    case 'UKULELE_FOUR_STRING_D_STANDARD':
      return {
        instrumentName: 'Ukulele',
        stringCount: 4,
        tuningName: 'D Standard',
        fretboardConfigId,
      };
    case 'UKULELE_FOUR_STRING_BARITONE_STANDARD':
      return {
        instrumentName: 'Ukulele',
        stringCount: 4,
        tuningName: 'Baritone Standard',
        fretboardConfigId,
      };
    case 'UKULELE_FOUR_STRING_BARITONE_ADGC':
      return {
        instrumentName: 'Ukulele',
        stringCount: 4,
        tuningName: 'Baritone (ADGC)',
        fretboardConfigId,
      };
    default:
      assertExhaustive(fretboardConfigId);
  }
};

const instruments: Array<Instrument> = ALL_FRETBOARD_CONFIG_IDS.map(
  getFretboardConfigDescription
).reduce((acc, description) => {
  const stringCountName = `${description.stringCount} String`;

  const instrument = acc.find((i) => i.name === description.instrumentName);

  if (!instrument) {
    acc.push({
      name: description.instrumentName,
      stringCounts: [
        {
          name: stringCountName,
          tunings: [
            {
              name: description.tuningName,
              fretboardConfigId: description.fretboardConfigId,
            },
          ],
        },
      ],
    });

    return acc;
  }

  const stringCount = instrument.stringCounts.find(
    (s) => s.name === stringCountName
  );

  if (!stringCount) {
    instrument.stringCounts.push({
      name: stringCountName,
      tunings: [
        {
          name: description.tuningName,
          fretboardConfigId: description.fretboardConfigId,
        },
      ],
    });

    return acc;
  }

  const tuning = stringCount.tunings.find(
    (t) => t.name === description.tuningName
  );

  if (!tuning) {
    stringCount.tunings.push({
      name: description.tuningName,
      fretboardConfigId: description.fretboardConfigId,
    });

    return acc;
  }

  throw new Error(
    `The following fretboard description has been defined more than once: ${JSON.stringify(
      description
    )}`
  );
}, new Array<Instrument>());

interface StateIndexes {
  instrumentIndex: number;
  stringCountIndex: number;
  tuningIndex: number;
}

interface State extends StateIndexes {
  initialFretboardConfigId: FretboardConfigId;
  initialBpm: number;
  handedness: FretboardHandedness;
  instrumentIndex: number;
  stringCountIndex: number;
  tuningIndex: number;
}

export type Action =
  | { type: 'SET_HANDEDNESS'; handedness: FretboardHandedness }
  | { type: 'SET_INSTRUMENT'; instrumentIndex: number }
  | { type: 'SET_STRING_COUNT'; stringCountIndex: number }
  | { type: 'SET_TUNING'; tuningIndex: number };

const initialState: State = {
  initialFretboardConfigId: 'GUITAR_SIX_STRING_E_STANDARD',
  initialBpm: 0,
  handedness: 'RIGHT',
  instrumentIndex: 0,
  stringCountIndex: 0,
  tuningIndex: 0,
};

const fretboardIdStateLookup: Map<FretboardConfigId, StateIndexes> =
  instruments.reduce((acc, instrument, instrumentIndex) => {
    for (
      let stringCountIndex = 0;
      stringCountIndex < instrument.stringCounts.length;
      stringCountIndex += 1
    ) {
      for (
        let tuningIndex = 0;
        tuningIndex < instrument.stringCounts[stringCountIndex].tunings.length;
        tuningIndex += 1
      ) {
        acc.set(
          instruments[instrumentIndex].stringCounts[stringCountIndex].tunings[
            tuningIndex
          ].fretboardConfigId,
          { instrumentIndex, stringCountIndex, tuningIndex }
        );
      }
    }
    return acc;
  }, new Map<FretboardConfigId, StateIndexes>());

// eslint-disable-next-line consistent-return
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_HANDEDNESS':
      return {
        ...state,
        handedness: action.handedness,
      };
    case 'SET_INSTRUMENT':
      return {
        ...state,
        instrumentIndex: action.instrumentIndex,
        stringCountIndex: 0,
        tuningIndex: 0,
      };
    case 'SET_STRING_COUNT':
      return {
        ...state,
        stringCountIndex: action.stringCountIndex,
        tuningIndex: 0,
      };
    case 'SET_TUNING':
      return {
        ...state,
        tuningIndex: action.tuningIndex,
      };
    default:
      assertExhaustive(action);
  }
};

const getFretboardConfigIdFromState = (state: State) =>
  instruments[state.instrumentIndex].stringCounts[state.stringCountIndex]
    .tunings[state.tuningIndex].fretboardConfigId;

const Choice = React.memo(
  ({
    choices: options,
    index,
    onChange,
  }: {
    choices: Array<NamedChoice>;
    index: number;
    onChange: (index: number) => void;
  }) =>
    options.length < 2 ? null : (
      <div className='mb-2 w-full last:mb-0'>
        <RadioGroup value={index} onChange={onChange} className='w-full'>
          <div className='flex justify-center'>
            {options.map((option, optionIndex) => (
              <RadioGroup.Option
                key={option.name}
                value={optionIndex}
                className={({ checked }) =>
                  `${
                    checked
                      ? 'bg-indigo-700 text-white hover:bg-indigo-600'
                      : 'bg-gray-700  text-gray-300 hover:bg-gray-600'
                  }
                  mr-2
                  flex
                  flex-1 cursor-pointer
                  items-center justify-center rounded-md
                  px-3
                  py-3
                  text-sm
                  transition
                  last:mr-0
                  focus:outline-none
                  focus:ring-2
                  focus:ring-indigo-500
                  focus:ring-offset-2
                  focus:ring-offset-gray-800
                `
                }
              >
                <RadioGroup.Label as='p'>{option.name}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    )
);

Choice.displayName = 'Option';

export default function Settings({ canonicalUrl }: Props) {
  const navigate = useNavigate();
  const [{ fretboardConfigId, bpm, handedness }, settingsDispatch] =
    useSettings();
  const [state, dispatch] = useReducer(reducer, {
    initialFretboardConfigId: fretboardConfigId,
    initialBpm: bpm,
    handedness,
    ...(fretboardIdStateLookup.get(fretboardConfigId) || initialState),
  });
  const [, setForceInitialStep] = useForceInitialStep();
  const naturalNoteFingerings = useNaturalNoteFingerings();

  const onHandednessChanged = useCallback(
    (handednessIndex) =>
      dispatch({
        type: 'SET_HANDEDNESS',
        handedness: handednesses[handednessIndex].handedness,
      }),
    [dispatch]
  );

  const onInstrumentChanged = useCallback(
    (instrumentIndex) => dispatch({ type: 'SET_INSTRUMENT', instrumentIndex }),
    [dispatch]
  );

  const onStringCountChanged = useCallback(
    (stringCountIndex) =>
      dispatch({ type: 'SET_STRING_COUNT', stringCountIndex }),
    [dispatch]
  );

  useEffect(() => {
    const newFretboardConfigId = getFretboardConfigIdFromState(state);
    settingsDispatch({
      type: 'SET_FRETBOARD_CONFIG',
      fretboardConfigId: newFretboardConfigId,
      handedness: state.handedness,
    });

    if (newFretboardConfigId === state.initialFretboardConfigId) {
      settingsDispatch({
        type: 'SET_BPM',
        bpm: state.initialBpm,
      });

      setForceInitialStep(false);
    } else {
      settingsDispatch({
        type: 'SET_BPM',
        bpm: minimumBpm,
      });

      setForceInitialStep(true);
    }
  }, [state, settingsDispatch, setForceInitialStep]);

  const fretboardConfig = getFretboardConfig(fretboardConfigId);

  return (
    <PageLayout.NonScrollableContent>
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>

      <div className='mx-auto flex h-full max-w-screen-lg flex-col items-center justify-center sm-h:mx-10'>
        <div className='relative mt-6 mb-3 flex w-full items-center justify-center sm:hidden sm-h:!hidden'>
          <button
            type='button'
            className='btn-text-only absolute left-3 sm-h:left-32'
            aria-label='Go Back'
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className='h-8 w-8' />
          </button>

          <h1
            className='
              mt-[-0.06em]
              text-3xl
              font-normal
              leading-normal
              text-gray-100
            '
          >
            Settings
          </h1>
        </div>

        <div className='my-4 flex h-full w-full flex-col items-center sm:justify-center'>
          <div
            className='
              z-10
              mb-4 flex
              w-full
              max-w-3xl
              flex-col
              px-[1.5em]
            '
          >
            <Choice
              choices={handednesses}
              index={handednesses.findIndex(
                (h) => h.handedness === state.handedness
              )}
              onChange={onHandednessChanged}
            />

            <Choice
              choices={instruments}
              index={state.instrumentIndex}
              onChange={onInstrumentChanged}
            />

            <Choice
              choices={instruments[state.instrumentIndex].stringCounts}
              index={state.stringCountIndex}
              onChange={onStringCountChanged}
            />

            {instruments[state.instrumentIndex].stringCounts[
              state.stringCountIndex
            ].tunings.length > 1 && (
              <Listbox
                value={state.tuningIndex}
                onChange={(tuningIndex) =>
                  dispatch({ type: 'SET_TUNING', tuningIndex })
                }
              >
                {({ open }) => (
                  <div className='relative'>
                    <Listbox.Button
                      className='
                        relative
                        w-full
                        cursor-pointer
                        rounded-md
                        border-none
                        bg-indigo-700
                        px-10
                        py-3
                        text-left
                        text-sm
                        text-white
                        shadow-sm
                        hover:bg-indigo-600
                        focus:outline-none
                        focus:ring-2
                        focus:ring-indigo-500
                        focus:ring-offset-2
                        focus:ring-offset-gray-800
                      '
                    >
                      <span className='block truncate text-center'>
                        {
                          instruments[state.instrumentIndex].stringCounts[
                            state.stringCountIndex
                          ].tunings[state.tuningIndex].name
                        }
                      </span>

                      <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                        <SelectorIcon
                          className='h-5 w-5 text-gray-300'
                          aria-hidden='true'
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave='transition ease-in duration-100'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Listbox.Options
                        className='
                          absolute
                          z-10
                          mt-1
                          max-h-60
                          w-full
                          overflow-auto
                          rounded-md
                          bg-gray-700
                          py-1
                          text-sm
                          shadow-lg
                          ring-1
                          ring-black
                          ring-opacity-5
                          focus:outline-none
                        '
                      >
                        {instruments[state.instrumentIndex].stringCounts[
                          state.stringCountIndex
                        ].tunings.map((tuning, tuningIndex) => (
                          <Listbox.Option
                            key={tuning.name}
                            className={({ active }) => `
                                ${active ? 'bg-indigo-600' : ''}
                                relative
                                cursor-default
                                select-none
                                py-2
                                px-9
                                text-white
                            `}
                            value={tuningIndex}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`
                                    block
                                    cursor-pointer
                                    truncate
                                    text-center
                                  `}
                                >
                                  {tuning.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={`
                                      ${
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-400'
                                      }
                                      absolute
                                      inset-y-0
                                      right-0
                                      flex items-center
                                      pr-4
                                    `}
                                  >
                                    <CheckIcon
                                      className='h-5 w-5'
                                      aria-hidden='true'
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            )}
          </div>

          <div className='w-full'>
            <Fretboard
              config={fretboardConfig}
              handedness={state.handedness}
              dotOpacity={1}
              showFretFingerings
              fretFingerings={naturalNoteFingerings}
            />
          </div>
        </div>
      </div>
    </PageLayout.NonScrollableContent>
  );
}

export const exportedForTesting = {
  fretboardIdStateLookup,
};
